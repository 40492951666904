import React from "react";
import "./Footer.css";

import logowhite_1 from "./../../assets/img/Logo_Potenz_white_1.png";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer text-light">
      <div className="container">
        <div className="row">
          <div className="col-md-4 flex-column d-flex justify-content-center align-items-center">
            <img
              src={logowhite_1}
              alt="Logo de la empresa"
              className="footer-logo"
            />
          </div>
          <div className="col-md-4 flex-column d-flex justify-content-center align-items-center">
            <div>
              <h3 className="regular">{t("Popular_Links")}</h3>
              <ul>
                <li>
                  <a href="/" className="text-light regular">
                    {t("Inicio")}
                  </a>
                </li>
                <li>
                  <a href="/quiene-somos" className="text-light regular">
                    {t("Quienes_somos")}
                  </a>
                </li>
                <li>
                  <a href="/catalogo" className="text-light regular">
                    {t("Catalogo")}
                  </a>
                </li>
                <li>
                  <a href="/distribuidores" className="text-light regular">
                    {t("Distribuidores")}
                  </a>
                </li>
                <li>
                  <a href="/contactanos" className="text-light regular">
                    {t("Contactanos")}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 flex-column d-flex  align-items-center">
            <div>
              <h3 className="regular">{t("Contactanos")}</h3>
              <p className="regular">
                {t("Address")}: 4ta avenida 5-30 zona 6 Colonia los Álamos, San
                Miguel Petapa.
              </p>
              <p className="regular">{t("Mail")}: info@potenzquimica.com.gt</p>
              <p className="regular">{t("Phone")}: 2319-9900</p>
              <div className="social-links">
                <a href="https://facebook.com" className="text-light">
                  <BsFacebook size={24} />
                </a>
                <a href="https://twitter.com" className="text-light">
                  <FaXTwitter size={24} />
                </a>
                <a href="https://instagram.com" className="text-light">
                  <BsInstagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom  text-light text-center py-3 regular">
        &copy; 2024 Potenz. Todos los derechos reservados.
      </div>
    </footer>
  );
}
