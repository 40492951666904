import React, { useEffect, useState } from "react";
import "./ProductoUnitario.css";
import Header from "./../../components/Header/Header";
import Footer from "./../../components/Footer/Footer";
import { useParams } from "react-router-dom";
//PRESENTACIONES
import p_250CC from "./../../assets/img/Catalogo_img/PRESENTACIONES/P_250CC.png";
import p_1L from "./../../assets/img/Catalogo_img/PRESENTACIONES/P_1L.png";
import p_5L from "./../../assets/img/Catalogo_img/PRESENTACIONES/P_5L.png";
import p_20L from "./../../assets/img/Catalogo_img/PRESENTACIONES/P_20L.png";
import p_200L from "./../../assets/img/Catalogo_img/PRESENTACIONES/P_200L.png";
import p_1KG from "./../../assets/img/Catalogo_img/PRESENTACIONES/P_1KG.png";
import p_25KG from "./../../assets/img/Catalogo_img/PRESENTACIONES/P_25KG.png";
import {
  Agrinutrientes_Img,
  BIOESTIMULANTES_img,
  BIOPROTECTORES_img,
  COADYUVANTES_img,
  CORRECTORES_DE_AGUA_Y_SUELO_img,
  ESPECIALIZADOS_img,
  HIDROSOLUBLES_img,
} from "../../utils/Images";
import Productos from "../Productos/Productos";
import { Fade, Slide } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";

import { useLanguage } from "./../../context/LanguageContext";

export default function ProductoUnitario() {
  const { language } = useLanguage();

  const { t } = useTranslation();

  let { nombre_producto } = useParams();
  const productos = [
    // AGRINUTRIENTES
    {
      nombre: t("Agrinutriente_Zn_b.nombre"),
      titulo: "Agrinutriente Zn+B",
      color: "#7abe00",
      png: Agrinutrientes_Img.AGRINUTRIENTE_Zn_b_png,
      webp: Agrinutrientes_Img.AGRINUTRIENTE_Zn_b_webp,
      avif: Agrinutrientes_Img.AGRINUTRIENTE_Zn_b_avif,
      extras: t("Agrinutriente_Zn_b.extras"),
      generalidades: t("Agrinutriente_Zn_b.generalidades"),
      composicion: [
        {
          componente: t("Agrinutriente_Zn_b.composicion.1.componente"),
          porcetanje: "10.10%",
        },
        {
          componente: t("Agrinutriente_Zn_b.composicion.2.componente"),
          porcetanje: "3.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Agrinutriente_Ca_b.nombre"),
      titulo: "Agrinutriente Ca+B",
      color: "#7abe00",
      png: Agrinutrientes_Img.AGRINUTRIENTE_Ca_b_png,
      webp: Agrinutrientes_Img.AGRINUTRIENTE_Ca_b_webp,
      avif: Agrinutrientes_Img.AGRINUTRIENTE_Ca_b_avif,
      extras: t("Agrinutriente_Ca_b.extras"),
      generalidades: t("Agrinutriente_Ca_b.generalidades"),
      composicion: [
        {
          componente: t("Agrinutriente_Ca_b.composicion.1.componente"),
          porcetanje: "8.00%",
        },
        {
          componente: t("Agrinutriente_Ca_b.composicion.2.componente"),
          porcetanje: "2.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Agrinutriente_17_17_17.nombre"),
      titulo: "Agrinutriente 17-17-17",
      color: "#7abe00",
      png: Agrinutrientes_Img.AGRINUTRIENTE_17_17_17_png,
      webp: Agrinutrientes_Img.AGRINUTRIENTE_17_17_17_webp,
      avif: Agrinutrientes_Img.AGRINUTRIENTE_17_17_17_avif,
      extras: t("Agrinutriente_17_17_17.extras"),
      generalidades: t("Agrinutriente_17_17_17.generalidades"),
      composicion: [
        {
          componente: t("Agrinutriente_17_17_17.composicion.1.componente"),
          porcetanje: "17.00%",
        },
        {
          componente: t("Agrinutriente_17_17_17.composicion.2.componente"),
          porcetanje: "17.00%",
        },
        {
          componente: t("Agrinutriente_17_17_17.composicion.3.componente"),
          porcetanje: "17.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Agrinutriente_B_1.nombre"),
      titulo: "Agrinutriente B",
      color: "#7abe00",
      png: Agrinutrientes_Img.AGRINUTRIENTE_B_png,
      webp: Agrinutrientes_Img.AGRINUTRIENTE_B_webp,
      avif: Agrinutrientes_Img.AGRINUTRIENTE_B_avif,
      extras: t("Agrinutriente_B_1.extras"),
      generalidades: t("Agrinutriente_B_1.generalidades"),
      composicion: [
        {
          componente: t("Agrinutriente_B_1.composicion.1.componente"),
          porcetanje: "8.00%",
        },
        {
          componente: t("Agrinutriente_B_1.composicion.2.componente"),
          porcetanje: "2.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    // ESPECIALIDADES
    {
      nombre: t("Meister_Papa_1.nombre"),
      titulo: "Meister Papa",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_PAPA_png,
      webp: ESPECIALIZADOS_img.MEISTER_PAPA_webp,
      avif: ESPECIALIZADOS_img.MEISTER_PAPA_avif,
      extras: t("Meister_Papa_1.extras"),
      generalidades: t("Meister_Papa_1.generalidades"),
      composicion: [
        {
          componente: t("Meister_Papa_1.composicion.1.componente"),
          porcetanje: "4.5%",
        },
        {
          componente: t("Meister_Papa_1.composicion.2.componente"),
          porcetanje: "2.0%",
        },
        {
          componente: t("Meister_Papa_1.composicion.3.componente"),
          porcetanje: "2.0%",
        },
        {
          componente: t("Meister_Papa_1.composicion.4.componente"),
          porcetanje: "0.2%",
        },
        {
          componente: t("Meister_Papa_1.composicion.5.componente"),
          porcetanje: "1.56%",
        },
        {
          componente: t("Meister_Papa_1.composicion.6.componente"),
          porcetanje: "0.125%",
        },
        {
          componente: t("Meister_Papa_1.composicion.7.componente"),
          porcetanje: "0.50%",
        },
        {
          componente: t("Meister_Papa_1.composicion.8.componente"),
          porcetanje: "0.25%",
        },
        {
          componente: t("Meister_Papa_1.composicion.9.componente"),
          porcetanje: "0.10%",
        },
        {
          componente: t("Meister_Papa_1.composicion.10.componente"),
          porcetanje: "0.15%",
        },
        {
          componente: t("Meister_Papa_1.composicion.11.componente"),
          porcetanje: "10.0%",
        },
        {
          componente: t("Meister_Papa_1.composicion.12.componente"),
          porcetanje: "1.50%",
        },
        {
          componente: t("Meister_Papa_1.composicion.13.componente"),
          porcetanje: "6.0%",
        },
        {
          componente: t("Meister_Papa_1.composicion.14.componente"),
          porcetanje: "6.0%",
        },
        {
          componente: t("Meister_Papa_1.composicion.15.componente"),
          porcetanje: "0.025%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Meister_Maíz_1.nombre"),
      titulo: "Meister Maíz",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_MAIZ_png,
      webp: ESPECIALIZADOS_img.MEISTER_MAIZ_webp,
      avif: ESPECIALIZADOS_img.MEISTER_MAIZ_avif,
      extras: t("Meister_Maíz_1.extras"),
      generalidades: t("Meister_Maíz_1.generalidades"),
      composicion: [
        {
          componente: t("Meister_Maíz_1.composicion.1.componente"),
          porcetanje: "4.5%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.2.componente"),
          porcetanje: "3.00%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.3.componente"),
          porcetanje: "2.0%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.4.componente"),
          porcetanje: "0.2%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.5.componente"),
          porcetanje: "1.56%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.6.componente"),
          porcetanje: "0.125%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.7.componente"),
          porcetanje: "0.50%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.8.componente"),
          porcetanje: "0.25%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.9.componente"),
          porcetanje: "0.10%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.10.componente"),
          porcetanje: "0.15%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.11.componente"),
          porcetanje: "10.0%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.12.componente"),
          porcetanje: "1.50%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.13.componente"),
          porcetanje: "6.0%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.14.componente"),
          porcetanje: "1.5%",
        },
        {
          componente: t("Meister_Maíz_1.composicion.15.componente"),
          porcetanje: "0.025%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Meister_Frijol_1.nombre"),
      titulo: "Meister Frijol",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_FRIJOL_png,
      webp: ESPECIALIZADOS_img.MEISTER_FRIJOL_webp,
      avif: ESPECIALIZADOS_img.MEISTER_FRIJOL_avif,
      extras: t("Meister_Frijol_1.extras"),
      generalidades: t("Meister_Frijol_1.generalidades"),
      composicion: [
        {
          componente: t("Meister_Frijol_1.composicion.1.componente"),
          porcetanje: "4.5%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.2.componente"),
          porcetanje: "2.0%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.3.componente"),
          porcetanje: "2.0%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.4.componente"),
          porcetanje: "0.2%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.5.componente"),
          porcetanje: "1.56%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.6.componente"),
          porcetanje: "0.125%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.7.componente"),
          porcetanje: "0.50%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.8.componente"),
          porcetanje: "0.25%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.9.componente"),
          porcetanje: "0.10%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.10.componente"),
          porcetanje: "0.15%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.11.componente"),
          porcetanje: "10.0%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.12.componente"),
          porcetanje: "1.50%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.13.componente"),
          porcetanje: "6.0%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.14.componente"),
          porcetanje: "1.5%",
        },
        {
          componente: t("Meister_Frijol_1.composicion.15.componente"),
          porcetanje: "0.025%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Meister_Café_1.nombre"),
      titulo: "Meister Café",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_CAFE_png,
      webp: ESPECIALIZADOS_img.MEISTER_CAFE_webp,
      avif: ESPECIALIZADOS_img.MEISTER_CAFE_avif,
      extras: t("Meister_Café_1.extras"),
      generalidades: t("Meister_Café_1.generalidades"),
      composicion: [
        {
          componente: t("Meister_Café_1.composicion.1.componente"),
          porcetanje: "4.5%",
        },
        {
          componente: t("Meister_Café_1.composicion.2.componente"),
          porcetanje: "2.0%",
        },
        {
          componente: t("Meister_Café_1.composicion.3.componente"),
          porcetanje: "2.0%",
        },
        {
          componente: t("Meister_Café_1.composicion.4.componente"),
          porcetanje: "0.2%",
        },
        {
          componente: t("Meister_Café_1.composicion.5.componente"),
          porcetanje: "1.56%",
        },
        {
          componente: t("Meister_Café_1.composicion.6.componente"),
          porcetanje: "0.125%",
        },
        {
          componente: t("Meister_Café_1.composicion.7.componente"),
          porcetanje: "0.50%",
        },
        {
          componente: t("Meister_Café_1.composicion.8.componente"),
          porcetanje: "0.25%",
        },
        {
          componente: t("Meister_Café_1.composicion.9.componente"),
          porcetanje: "0.10%",
        },
        {
          componente: t("Meister_Café_1.composicion.10.componente"),
          porcetanje: "0.15%",
        },
        {
          componente: t("Meister_Café_1.composicion.11.componente"),
          porcetanje: "10.0%",
        },
        {
          componente: t("Meister_Café_1.composicion.12.componente"),
          porcetanje: "1.50%",
        },
        {
          componente: t("Meister_Café_1.composicion.13.componente"),
          porcetanje: "6.0%",
        },
        {
          componente: t("Meister_Café_1.composicion.14.componente"),
          porcetanje: "1.5%",
        },
        {
          componente: t("Meister_Café_1.composicion.15.componente"),
          porcetanje: "0.025%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Meister_Ajonjolí_1.nombre"),
      titulo: "Meister Ajonjolí",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_AJONJOLI_png,
      webp: ESPECIALIZADOS_img.MEISTER_AJONJOLI_webp,
      avif: ESPECIALIZADOS_img.MEISTER_AJONJOLI_avif,
      extras: t("Meister_Ajonjolí_1.extras"),
      generalidades: t("Meister_Ajonjolí_1.generalidades"),
      composicion: [
        {
          componente: t("Meister_Ajonjolí_1.composicion.1.componente"),
          porcetanje: "4.5%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.2.componente"),
          porcetanje: "2.0%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.3.componente"),
          porcetanje: "2.0%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.4.componente"),
          porcetanje: "0.2%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.5.componente"),
          porcetanje: "1.56%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.6.componente"),
          porcetanje: "0.125%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.7.componente"),
          porcetanje: "0.50%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.8.componente"),
          porcetanje: "0.25%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.9.componente"),
          porcetanje: "0.10%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.10.componente"),
          porcetanje: "0.15%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.11.componente"),
          porcetanje: "10.0%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.12.componente"),
          porcetanje: "1.50%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.13.componente"),
          porcetanje: "6.0%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.14.componente"),
          porcetanje: "1.5%",
        },
        {
          componente: t("Meister_Ajonjolí_1.composicion.15.componente"),
          porcetanje: "0.025%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Meister_Plus_1.nombre"),
      titulo: "Meister Plus",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_PLUS_png,
      webp: ESPECIALIZADOS_img.MEISTER_PLUS_webp,
      avif: ESPECIALIZADOS_img.MEISTER_PLUS_avif,
      extras: t("Meister_Plus_1.extras"),
      generalidades: t("Meister_Plus_1.generalidades"),
      composicion: [
        {
          componente: t("Meister_Plus_1.composicion.1.componente"),
          porcetanje: "4.5%",
        },
        {
          componente: t("Meister_Plus_1.composicion.2.componente"),
          porcetanje: "2.0%",
        },
        {
          componente: t("Meister_Plus_1.composicion.3.componente"),
          porcetanje: "2.0%",
        },
        {
          componente: t("Meister_Plus_1.composicion.4.componente"),
          porcetanje: "0.2%",
        },
        {
          componente: t("Meister_Plus_1.composicion.5.componente"),
          porcetanje: "1.56%",
        },
        {
          componente: t("Meister_Plus_1.composicion.6.componente"),
          porcetanje: "0.125%",
        },
        {
          componente: t("Meister_Plus_1.composicion.7.componente"),
          porcetanje: "0.50%",
        },
        {
          componente: t("Meister_Plus_1.composicion.8.componente"),
          porcetanje: "0.25%",
        },
        {
          componente: t("Meister_Plus_1.composicion.9.componente"),
          porcetanje: "0.10%",
        },
        {
          componente: t("Meister_Plus_1.composicion.10.componente"),
          porcetanje: "0.15%",
        },
        {
          componente: t("Meister_Plus_1.composicion.11.componente"),
          porcetanje: "10.0%",
        },
        {
          componente: t("Meister_Plus_1.composicion.12.componente"),
          porcetanje: "1.50%",
        },
        {
          componente: t("Meister_Plus_1.composicion.13.componente"),
          porcetanje: "6.0%",
        },
        {
          componente: t("Meister_Plus_1.composicion.14.componente"),
          porcetanje: "1.5%",
        },
        {
          componente: t("Meister_Plus_1.composicion.15.componente"),
          porcetanje: "0.025%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("8-24-0_1.nombre"),
      titulo: "8-24-0",
      color: "#91016C",
      png: ESPECIALIZADOS_img.ES_8_24_0_png,
      webp: ESPECIALIZADOS_img.ES_8_24_0_webp,
      avif: ESPECIALIZADOS_img.ES_8_24_0_avif,
      extras: t("8-24-0_1.extras"),
      generalidades: t("8-24-0_1.generalidades"),
      composicion: [
        {
          componente: t("8-24-0_1.composicion.1.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("8-24-0_1.composicion.2.componente"),
          porcetanje: "30.00%",
        },
      ],
      avertensia: t("8-24-0_1.advertencia"),
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Humato_De_Calcio_1.nombre"),
      titulo: "Humato De Calcio",
      color: "#91016C",
      png: ESPECIALIZADOS_img.HUMATO_DE_CALCIO_png,
      webp: ESPECIALIZADOS_img.HUMATO_DE_MAGNESIO_webp,
      avif: ESPECIALIZADOS_img.HUMATO_DE_CALCIO_avif,
      extras: t("Humato_De_Calcio_1.extras"),
      generalidades: t("Humato_De_Calcio_1.generalidades"),
      composicion: [
        {
          componente: t("Humato_De_Calcio_1.composicion.1.componente"),
          porcetanje: "20.00%",
        },
        {
          componente: t("Humato_De_Calcio_1.composicion.2.componente"),
          porcetanje: "12.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Humato_De_Magnesio_1.nombre"),
      titulo: "Humato De Magnesio",
      color: "#91016C",
      png: ESPECIALIZADOS_img.HUMATO_DE_MAGNESIO_png,
      webp: ESPECIALIZADOS_img.HUMATO_DE_MAGNESIO_webp,
      avif: ESPECIALIZADOS_img.HUMATO_DE_MAGNESIO_avif,
      extras: t("Humato_De_Magnesio_1.extras"),
      generalidades: t("Humato_De_Magnesio_1.generalidades"),
      composicion: [
        {
          componente: t("Humato_De_Magnesio_1.composicion.1.componente"),
          porcetanje: "12.00%",
        },
        {
          componente: t("Humato_De_Magnesio_1.composicion.2.componente"),
          porcetanje: "8.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Potenz-K_1.nombre"),
      titulo: "Potenz-K",
      color: "#91016C",
      png: ESPECIALIZADOS_img.POTENZ_K_png,
      webp: ESPECIALIZADOS_img.POTENZ_K_webp,
      avif: ESPECIALIZADOS_img.POTENZ_K_avif,
      extras: t("Potenz-K_1.extras"),
      generalidades: t("Potenz-K_1.generalidades"),
      composicion: [
        {
          componente: t("Potenz-K_1.composicion.1.componente"),
          porcetanje: "25.00%",
        },
        {
          componente: t("Potenz-K_1.composicion.2.componente"),
          porcetanje: "10.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Nitrato_De_Zinc_1.nombre"),
      titulo: "Nitrato De Zinc",
      color: "#91016C",
      png: ESPECIALIZADOS_img.NITRATO_DE_ZINC_png,
      webp: ESPECIALIZADOS_img.NITRATO_DE_ZINC_webp,
      avif: ESPECIALIZADOS_img.NITRATO_DE_ZINC_avif,
      extras: t("Nitrato_De_Zinc_1.extras"),
      generalidades: t("Nitrato_De_Zinc_1.generalidades"),
      composicion: [
        {
          componente: t("Nitrato_De_Zinc_1.composicion.1.componente"),
          porcetanje: "19.00%",
        },
        {
          componente: t("Nitrato_De_Zinc_1.composicion.2.componente"),
          porcetanje: "8.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Potenz_Brixx_1.nombre"),
      titulo: "Potenz Brixx",
      color: "#91016C",
      png: ESPECIALIZADOS_img.POTENZ_BRIXX_png,
      webp: ESPECIALIZADOS_img.POTENZ_BRIXX_webp,
      avif: ESPECIALIZADOS_img.POTENZ_BRIXX_avif,
      extras: t("Potenz_Brixx_1.extras"),
      generalidades: t("Potenz_Brixx_1.generalidades"),
      composicion: [
        {
          componente: t("Potenz_Brixx_1.composicion.1.componente"),
          porcetanje: "25.00%",
        },
        {
          componente: t("Potenz_Brixx_1.composicion.2.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("Potenz_Brixx_1.composicion.3.componente"),
          porcetanje: "0.50%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Mas_Cana_1.nombre"),
      titulo: "Más Caña",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MAS_CANA_png,
      webp: ESPECIALIZADOS_img.MAS_CANA_webp,
      avif: ESPECIALIZADOS_img.MAS_CANA_avif,
      extras: t("Mas_Cana_1.extras"),
      generalidades: t("Mas_Cana_1.generalidades"),
      composicion: [
        {
          componente: t("Mas_Cana_1.composicion.1.componente"),
          porcetanje: "8.00%",
        },
        {
          componente: t("Mas_Cana_1.composicion.2.componente"),
          porcetanje: "7.12%",
        },
        {
          componente: t("Mas_Cana_1.composicion.3.componente"),
          porcetanje: "3.76%",
        },
        {
          componente: t("Mas_Cana_1.composicion.4.componente"),
          porcetanje: "0.50%",
        },
        {
          componente: t("Mas_Cana_1.composicion.5.componente"),
          porcetanje: "0.0625%",
        },
        {
          componente: t("Mas_Cana_1.composicion.6.componente"),
          porcetanje: "0.375%",
        },
        {
          componente: t("Mas_Cana_1.composicion.7.componente"),
          porcetanje: "0.025%",
        },
        {
          componente: t("Mas_Cana_1.composicion.8.componente"),
          porcetanje: "0.031%",
        },
        {
          componente: t("Mas_Cana_1.composicion.9.componente"),
          porcetanje: "0.037%",
        },
        {
          componente: t("Mas_Cana_1.composicion.10.componente"),
          porcetanje: "0.006%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Fost_Alexin-K_1.nombre"),
      titulo: "Fost Alexin-K",
      color: "#91016C",
      png: ESPECIALIZADOS_img.FOST_ALEXIN_K_png,
      webp: ESPECIALIZADOS_img.FOST_ALEXIN_K_webp,
      avif: ESPECIALIZADOS_img.FOST_ALEXIN_K_avif,
      extras: t("Fost_Alexin-K_1.extras"),
      generalidades: t("Fost_Alexin-K_1.generalidades"),
      composicion: [
        {
          componente: t("Fost_Alexin-K_1.composicion.1.componente"),
          porcetanje: "18.00%",
        },
        {
          componente: t("Fost_Alexin-K_1.composicion.2.componente"),
          porcetanje: "22.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Microplus_1.nombre"),
      titulo: "Microplus",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MICROPLUS_png,
      webp: ESPECIALIZADOS_img.MICROPLUS_webp,
      avif: ESPECIALIZADOS_img.MICROPLUS_avif,
      extras: t("Microplus_1.extras"),
      generalidades: t("Microplus_1.generalidades"),
      composicion: [
        {
          componente: t("Microplus_1.composicion.1.componente"),
          porcetanje: "5.40%",
        },
        {
          componente: t("Microplus_1.composicion.2.componente"),
          porcetanje: "4.00%",
        },
        {
          componente: t("Microplus_1.composicion.3.componente"),
          porcetanje: "2.60%",
        },
        {
          componente: t("Microplus_1.composicion.4.componente"),
          porcetanje: "1.55%",
        },
        {
          componente: t("Microplus_1.composicion.5.componente"),
          porcetanje: "1.45%",
        },
        {
          componente: t("Microplus_1.composicion.6.componente"),
          porcetanje: "0.50%",
        },
        {
          componente: t("Microplus_1.composicion.7.componente"),
          porcetanje: "2.30%",
        },
        {
          componente: t("Microplus_1.composicion.8.componente"),
          porcetanje: "2.62%",
        },
        {
          componente: t("Microplus_1.composicion.9.componente"),
          porcetanje: "8.31%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "1 Kg",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "25 Kgq",
        },
      ],
    },
    {
      nombre: t("Quelatt_Fe_1.nombre"),
      titulo: "Quelatt Fe",
      color: "#91016C",
      png: ESPECIALIZADOS_img.QUELATT_Fe_png,
      webp: ESPECIALIZADOS_img.QUELATT_Fe_webp,
      avif: ESPECIALIZADOS_img.QUELATT_Fe_avif,
      extras: t("Quelatt_Fe_1.extras"),
      generalidades: t("Quelatt_Fe_1.generalidades"),
      composicion: [
        {
          componente: t("Quelatt_Fe_1.composicion.1.componente"),
          porcetanje: "13.00%",
        },
        {
          componente: t("Quelatt_Fe_1.composicion.2.componente"),
          porcetanje: "52.50%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "1 kG",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "5 Kg",
        },
      ],
    },
    {
      nombre: t("10-34-0_1.nombre"),
      titulo: "10-34-0",
      color: "#91016C",
      png: ESPECIALIZADOS_img.ES_10_34_0_png,
      webp: ESPECIALIZADOS_img.ES_10_34_0_webp,
      avif: ESPECIALIZADOS_img.ES_10_34_0_avif,
      extras: t("10-34-0_1.extras"),
      generalidades: t("10-34-0_1.generalidades"),
      composicion: [
        {
          componente: t("10-34-0_1.composicion.1.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("10-34-0_1.composicion.2.componente"),
          porcetanje: "34.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    // BIOESTIMULANTES
    {
      nombre: t("Atp-K_1.nombre"),
      titulo: "Atp-K",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.ATP_K_png,
      webp: BIOESTIMULANTES_img.ATP_K_webp,
      avif: BIOESTIMULANTES_img.ATP_K_avif,
      extras: t("Atp-K_1.extras"),
      generalidades: t("Atp-K_1.generalidades"),
      composicion: [
        {
          componente: t("Atp-K_1.composicion.1.componente"),
          porcetanje: "30.00%",
        },
        {
          componente: t("Atp-K_1.composicion.2.componente"),
          porcetanje: "1.5%",
        },
        {
          componente: t("Atp-K_1.composicion.3.componente"),
          porcetanje: "5.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_250CC,
          tituloPresentacion: "250 CC",
        },
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Master_Flower_1.nombre"),
      titulo: "Master Flower",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.MASTER_FLOWER_png,
      webp: BIOESTIMULANTES_img.MASTER_FLOWER_webp,
      avif: BIOESTIMULANTES_img.MASTER_FLOWER_avif,
      extras: t("Master_Flower_1.extras"),
      generalidades: t("Master_Flower_1.generalidades"),
      composicion: [
        {
          componente: t("Master_Flower_1.composicion.1.componente"),
          porcetanje: "17.00%",
        },
        {
          componente: t("Master_Flower_1.composicion.2.componente"),
          porcetanje: "3.00%",
        },
        {
          componente: t("Master_Flower_1.composicion.3.componente"),
          porcetanje: "2.00%",
        },
        {
          componente: t("Master_Flower_1.composicion.4.componente"),
          porcetanje: "1.80%",
        },
        {
          componente: t("Master_Flower_1.composicion.5.componente"),
          porcetanje: "0.80%",
        },
        {
          componente: t("Master_Flower_1.composicion.6.componente"),
          porcetanje: "0.80%",
        },
        {
          componente: t("Master_Flower_1.composicion.7.componente"),
          porcetanje: "2.40%",
        },
        {
          componente: t("Master_Flower_1.composicion.8.componente"),
          porcetanje: "0.60%",
        },
        {
          componente: t("Master_Flower_1.composicion.9.componente"),
          porcetanje: "",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_250CC,
          tituloPresentacion: "250 CC",
        },
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Potenzamin_50_1.nombre"),
      titulo: "Potenzamin 50",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.POTENZAMIN_50_png,
      webp: BIOESTIMULANTES_img.POTENZAMIN_50_webp,
      avif: BIOESTIMULANTES_img.POTENZAMIN_50_avif,
      extras: t("Potenzamin_50_1.extras"),
      generalidades: t("Potenzamin_50_1.generalidades"),
      composicion: [
        {
          componente: t("Potenzamin_50_1.composicion.1.componente"),
          porcetanje: "50.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Root_Drive_1.nombre"),
      titulo: "Root Drive",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.ROOT_DRIVE_png,
      webp: BIOESTIMULANTES_img.ROOT_DRIVE_webp,
      avif: BIOESTIMULANTES_img.ROOT_DRIVE_avif,
      extras: t("Root_Drive_1.extras"),
      generalidades: t("Root_Drive_1.generalidades"),
      composicion: [
        {
          componente: t("Root_Drive_1.composicion.1.componente"),
          porcetanje: "15.00%",
        },
        {
          componente: t("Root_Drive_1.composicion.2.componente"),
          porcetanje: "2.00%",
        },
        {
          componente: t("Root_Drive_1.composicion.3.componente"),
          porcetanje: "7.50%",
        },
        {
          componente: t("Root_Drive_1.composicion.4.componente"),
          porcetanje: "7.50%",
        },
        {
          componente: t("Root_Drive_1.composicion.5.componente"),
          porcetanje: "15.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Alpromar_30_SL_1.nombre"),
      titulo: "Alpromar 30 SL",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.ALPROMAR_30_SL_png,
      webp: BIOESTIMULANTES_img.ALPROMAR_30_SL_webp,
      avif: BIOESTIMULANTES_img.ALPROMAR_30_SL_avif,
      extras: t("Alpromar_30_SL_1.extras"),
      generalidades: t("Alpromar_30_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Alpromar_30_SL_1.composicion.1.componente"),
          porcetanje: "30.00%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.2.componente"),
          porcetanje: "18.60%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.3.componente"),
          porcetanje: "18.60%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.4.componente"),
          porcetanje: "18.60%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.5.componente"),
          porcetanje: "0.33%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.6.componente"),
          porcetanje: "1.50%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.7.componente"),
          porcetanje: "0.60%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.8.componente"),
          porcetanje: "0.30%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.9.componente"),
          porcetanje: "0.075%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.10.componente"),
          porcetanje: "0.30%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.11.componente"),
          porcetanje: "0.006%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.12.componente"),
          porcetanje: "0.06%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.13.componente"),
          porcetanje: "0.60%",
        },
        {
          componente: t("Alpromar_30_SL_1.composicion.14.componente"),
          porcetanje: "4.689%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    // {
    //   nombre: "Aminoácidos 80%",
    //   color: "#F4C241",
    //   imagen: aminoacidos_80,
    //   extras: "Bioestimulante orgánico",
    //   generalidades:
    //     "Formulación con un 80% de aminoácidos levógiros de origen vegetal. Estos iniciadores de fitohormonas estimulan reacciones fisiológicas, combaten el estrés y proveen energía para un óptimo desarrollo del cultivo. Mejora la absorción de nutrientes y promueve el crecimiento saludable de las plantas.",
    //   composicion: [
    //     {
    //       componente: "Aminoácidos",
    //       porcetanje: "80.00%",
    //     },
    //   ],
    //   presentaciones: [
    //     {
    //       imgPresentacion: p_1KG,
    //       tituloPresentacion: "500 gr",
    //     },
    //     {
    //       imgPresentacion: p_25KG,
    //       tituloPresentacion: "20 kg",
    //     },
    //   ],
    // },
    {
      nombre: t("15-15-15_Master_1.nombre"),
      titulo: "15-15-15 Master",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.MASTER_15_15_15_png,
      webp: BIOESTIMULANTES_img.MASTER_15_15_15_webp,
      avif: BIOESTIMULANTES_img.MASTER_15_15_15_avif,
      extras: t("15-15-15_Master_1.extras"),
      generalidades: t("15-15-15_Master_1.generalidades"),
      composicion: [
        {
          componente: t("15-15-15_Master_1.composicion.1.componente"),
          porcetanje: "15.00%",
        },
        {
          componente: t("15-15-15_Master_1.composicion.2.componente"),
          porcetanje: "15.00%",
        },
        {
          componente: t("15-15-15_Master_1.composicion.3.componente"),
          porcetanje: "15.00%",
        },
        {
          componente: t("15-15-15_Master_1.composicion.4.componente"),
          porcetanje: "2.00%",
        },
        {
          componente: t("15-15-15_Master_1.composicion.5.componente"),
          porcetanje: "1.00%",
        },
        {
          componente: t("15-15-15_Master_1.composicion.6.componente"),
          porcetanje: "5.00%",
        },
        {
          componente: t("15-15-15_Master_1.composicion.7.componente"),
          porcetanje: "5.00%",
        },
        {
          componente: t("15-15-15_Master_1.composicion.8.componente"),
          porcetanje: "0.03%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "1 Kg",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "25 Kg",
        },
      ],
    },
    {
      nombre: t("Microamin_1.nombre"),
      titulo: "Microamin",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.MICROAMIN_png,
      webp: BIOESTIMULANTES_img.MICROAMIN_webp,
      avif: BIOESTIMULANTES_img.MICROAMIN_avif,
      extras: t("Microamin_1.extras"),
      generalidades: t("Microamin_1.generalidades"),
      composicion: [
        {
          componente: t("Microamin_1.composicion.1.componente"),
          porcetanje: "59.00%",
        },
        {
          componente: t("Microamin_1.composicion.2.componente"),
          porcetanje: "2.00%",
        },
        {
          componente: t("Microamin_1.composicion.3.componente"),
          porcetanje: "1.00%",
        },
        {
          componente: t("Microamin_1.composicion.4.componente"),
          porcetanje: "1.00%",
        },
        {
          componente: t("Microamin_1.composicion.5.componente"),
          porcetanje: "0.108%",
        },
        {
          componente: t("Microamin_1.composicion.6.componente"),
          porcetanje: "0.20%",
        },
        {
          componente: t("Microamin_1.composicion.7.componente"),
          porcetanje: "0.25%",
        },
        {
          componente: t("Microamin_1.composicion.8.componente"),
          porcetanje: "1.104%",
        },
        {
          componente: t("Microamin_1.composicion.9.componente"),
          porcetanje: "2.0578%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "350 gr",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "15 kg",
        },
      ],
    },
    {
      nombre: t("Alpromar_100_1.nombre"),
      titulo: "Alpromar 100",
      color: "#F4C241",
      presentacioneImages: [BIOESTIMULANTES_img.BAG_ALRPOMAR_100_png],
      png: BIOESTIMULANTES_img.ALRPOMAR_100_png,
      webp: BIOESTIMULANTES_img.ALRPOMAR_100_webp,
      avif: BIOESTIMULANTES_img.ALRPOMAR_100_avif,
      extras: t("Alpromar_100_1.extras"),
      generalidades: t("Alpromar_100_1.generalidades"),
      composicion: [
        {
          componente: t("Alpromar_100_1.composicion.1.componente"),
          porcetanje: "99.94%",
        },
        {
          componente: t("Alpromar_100_1.composicion.2.componente"),
          porcetanje: "0.06%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "500 gr",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "20 Kg",
        },
      ],
    },
    // BIOPROTECTORES
    {
      nombre: t("Fange_20_SL_1.nombre"),
      titulo: "Fange 20 SL",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.FANGE_20_png,
      webp: BIOPROTECTORES_img.FANGE_20_webp,
      avif: BIOPROTECTORES_img.FANGE_20_avif,
      extras: t("Fange_20_SL_1.extras"),
      generalidades: t("Fange_20_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Fange_20_SL_1.composicion.1.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("Fange_20_SL_1.composicion.2.componente"),
          porcetanje: "10.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_250CC,
          tituloPresentacion: "250 CC",
        },
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("K-Óleo_1.nombre"),
      titulo: "K-oleo",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.K_OLEO_png,
      webp: BIOPROTECTORES_img.K_OLEO_webp,
      avif: BIOPROTECTORES_img.K_OLEO_avif,
      extras: t("K-Óleo_1.extras"),
      generalidades: t("K-Óleo_1.generalidades"),
      composicion: [
        {
          componente: t("K-Óleo_1.composicion.1.componente"),
          porcetanje: "10.10%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Omicron_Bio_15_SL_1.nombre"),
      titulo: "Omicron Bio 15 SL",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.OMICRON_BIO_15_SL_png,
      webp: BIOPROTECTORES_img.OMICRON_BIO_15_SL_webp,
      avif: BIOPROTECTORES_img.OMICRON_BIO_15_SL_avif,
      extras: t("Omicron_Bio_15_SL_1.extras"),
      generalidades: t("Omicron_Bio_15_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Omicron_Bio_15_SL_1.composicion.1.componente"),
          porcetanje: "15.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
      ],
    },
    {
      nombre: t("Omicron_BF_2000_1.nombre"),
      titulo: "Omicron BF 2000",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.OMICRON_BG_2000_png,
      webp: BIOPROTECTORES_img.OMICRON_BG_2000_webp,
      avif: BIOPROTECTORES_img.OMICRON_BG_2000_avif,
      extras: t("Omicron_BF_2000_1.extras"),
      generalidades: t("Omicron_BF_2000_1.generalidades"),
      composicion: [
        {
          componente: t("Omicron_BF_2000_1.composicion.1.componente"),
          porcetanje: "12.00%",
        },
        {
          componente: t("Omicron_BF_2000_1.composicion.2.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("Omicron_BF_2000_1.composicion.3.componente"),
          porcetanje: "6.00%",
        },
        {
          componente: t("Omicron_BF_2000_1.composicion.4.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("Omicron_BF_2000_1.composicion.5.componente"),
          porcetanje: "2.00%",
        },
        {
          componente: t("Omicron_BF_2000_1.composicion.6.componente"),
          porcetanje: "60.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_250CC,
          tituloPresentacion: "250cc",
        },
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
      ],
    },
    {
      nombre: t("Potenz_Neen_1.nombre"),
      titulo: "Potenz Neen",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.POTENZ_NEEM_png,
      webp: BIOPROTECTORES_img.POTENZ_NEEM_webp,
      avif: BIOPROTECTORES_img.POTENZ_NEEM_avif,
      extras: t("Potenz_Neen_1.extras"),
      generalidades: t("Potenz_Neen_1.generalidades"),
      composicion: [
        {
          componente: t("Potenz_Neen_1.composicion.1.componente"),
          porcetanje: "98.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Karajo_Ec_1.nombre"),
      titulo: "Karajo Ec",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.KARAJO_EC_png,
      webp: BIOPROTECTORES_img.KARAJO_EC_webp,
      avif: BIOPROTECTORES_img.KARAJO_EC_avif,
      extras: t("Karajo_Ec_1.extras"),
      generalidades: t("Karajo_Ec_1.generalidades"),
      composicion: [
        {
          componente: t("Karajo_Ec_1.composicion.1.componente"),
          porcetanje: "42.5%",
        },
        {
          componente: t("Karajo_Ec_1.composicion.2.componente"),
          porcetanje: "42.5%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Potenzsil_1.nombre"),
      titulo: "Potenzsil",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.POTENZSIL_png,
      webp: BIOPROTECTORES_img.POTENZSIL_webp,
      avif: BIOPROTECTORES_img.POTENZSIL_avif,
      extras: t("Potenzsil_1.extras"),
      generalidades: t("Potenzsil_1.generalidades"),
      composicion: [
        {
          componente: t("Potenzsil_1.composicion.1.componente"),
          porcetanje: "52.52%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Lange_Leben_1.nombre"),
      titulo: "Lange Leben",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.LANGE_LEBEN_png,
      webp: BIOPROTECTORES_img.LANGE_LEBEN_webp,
      avif: BIOPROTECTORES_img.LANGE_LEBEN_avif,
      extras: t("Lange_Leben_1.extras"),
      generalidades: t("Lange_Leben_1.generalidades"),
      composicion: [
        {
          componente: t("Lange_Leben_1.composicion.1.componente"),
          porcetanje: "20.00%",
        },
        {
          componente: t("Lange_Leben_1.composicion.2.componente"),
          porcetanje: "15.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Karanja_85_SL_1.nombre"),
      titulo: "Karanja 85 SL",
      png: BIOPROTECTORES_img.KARANJA_85_SL_png,
      webp: BIOPROTECTORES_img.KARANJA_85_SL_webp,
      avif: BIOPROTECTORES_img.KARANJA_85_SL_avif,
      extras: t("Karanja_85_SL_1.extras"),
      generalidades: t("Karanja_85_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Karanja_85_SL_1.composicion.1.componente"),
          porcetanje: "85.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("K_OLEO_CANELA.nombre"),
      titulo: "K-Óleo + Canela",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.K_OLEO_CANELA_png,
      webp: BIOPROTECTORES_img.K_OLEO_CANELA_webp,
      avif: BIOPROTECTORES_img.K_OLEO_CANELA_avif,
      extras: t("K_OLEO_CANELA.extras"),
      generalidades: t("K_OLEO_CANELA.generalidades"),
      composicion: [
        {
          componente: t("K_OLEO_CANELA.composicion.1.componente"),
          porcetanje: "8.00%",
        },
        {
          componente: t("K_OLEO_CANELA.composicion.2.componente"),
          porcetanje: "3.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    //Correctores De Agua Y Suelo
    {
      nombre: t("Abland_SL_1.nombre"),
      titulo: "Abland_SL",
      color: "#05A0EE",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_avif,
      extras: t("Abland_SL_1.extras"),
      generalidades: t("Abland_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Abland_SL_1.composicion.1.componente"),
          porcetanje: "20.00%",
        },
        {
          componente: t("Abland_SL_1.composicion.2.componente"),
          porcetanje: "50.00%",
        },
        {
          componente: t("Abland_SL_1.composicion.3.componente"),
          porcetanje: "50.00%",
        },
        {
          componente: t("Abland_SL_1.composicion.4.componente"),
          porcetanje: "15.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_250CC,
          tituloPresentacion: "250cc",
        },
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Abland_SL_75_1.nombre"),
      titulo: "Abland SL 75",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_75_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_75_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_75_avif,
      color: "#05A0EE",
      extras: t("Abland_SL_75_1.extras"),
      generalidades: t("Abland_SL_75_1.generalidades"),
      composicion: [
        {
          componente: t("Abland_SL_75_1.composicion.1.componente"),
          porcetanje: "50.00%",
        },
        {
          componente: t("Abland_SL_75_1.composicion.2.componente"),
          porcetanje: "20.00%",
        },
        {
          componente: t("Abland_SL_75_1.composicion.3.componente"),
          porcetanje: "10.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Abland_N_70_SL_1.nombre"),
      titulo: "Abland N 70 SL",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_N_70_SL_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_N_70_SL_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_N_70_SL_avif,
      color: "#05A0EE",
      extras: t("Abland_N_70_SL_1.extras"),
      generalidades: t("Abland_N_70_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Abland_N_70_SL_1.composicion.1.componente"),
          porcetanje: "70.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_250CC,
          tituloPresentacion: "250cc",
        },
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Abland_P_80_SL_1.nombre"),
      titulo: "Abland P 80 SL",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_P_80_SL_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_P_80_SL_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_P_80_SL_avif,
      color: "#05A0EE",
      extras: t("Abland_P_80_SL_1.extras"),
      generalidades: t("Abland_P_80_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Abland_P_80_SL_1.composicion.1.componente"),
          porcetanje: "80.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_250CC,
          tituloPresentacion: "250cc",
        },
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Abland_85_SL.nombre"),
      titulo: "Abland 85 SL",
      color: "#05A0EE",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.Litro_Abland_N_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.Litro_Abland_N_png,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.Litro_Abland_N_png,
      extras: t("Abland_85_SL_1.extras"),
      generalidades: t("Abland_85_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Abland_85_SL_1.composicion.1.componente"),
          porcetanje: "50.00%",
        },
        {
          componente: t("Abland_85_SL_1.composicion.2.componente"),
          porcetanje: "20.00%",
        },
        {
          componente: t("Abland_85_SL_1.composicion.3.componente"),
          porcetanje: "15.00%",
        },
        {
          componente: t("Abland_85_SL_1.composicion.4.componente"),
          porcetanje: "15.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Inditur-Acid_45_SL_1.nombre"),
      titulo: "Inditur-Acid 45 SL",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.INDITUR_ACID_45_SL_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.INDITUR_ACID_45_SL_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.INDITUR_ACID_45_SL_avif,
      color: "#05A0EE",
      extras: t("Inditur-Acid_45_SL_1.extras"),
      generalidades: t("Inditur-Acid_45_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Inditur-Acid_45_SL_1.composicion.1.componente"),
          porcetanje: "25.00%",
        },
        {
          componente: t("Inditur-Acid_45_SL_1.composicion.2.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("Inditur-Acid_45_SL_1.composicion.3.componente"),
          porcetanje: "10.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_250CC,
          tituloPresentacion: "250cc",
        },
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("HCA-25_1.nombre"),
      titulo: "HCA-25",
      color: "#05A0EE",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.HCA_25_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.HCA_25_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.HCA_25_avif,
      extras: t("HCA-25_1.extras"),
      generalidades: t("HCA-25_1.generalidades"),
      composicion: [
        {
          componente: t("HCA-25_1.composicion.1.componente"),
          porcetanje: "10.10%",
        },
        {
          componente: t("HCA-25_1.composicion.2.componente"),
          porcetanje: "3.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Aquamild_40_SL_1.nombre"),
      titulo: "Aquamild 40 SL",
      color: "#05A0EE",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.AQUAMILD_40_SL_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.AQUAMILD_40_SL_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.AQUAMILD_40_SL_avif,
      extras: t("Aquamild_40_SL_1.extras"),
      generalidades: t("Aquamild_40_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Aquamild_40_SL_1.composicion.1.componente"),
          porcetanje: "40.00%",
        },
        {
          componente: t("Aquamild_40_SL_1.composicion.2.componente"),
          porcetanje: "25.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Descomp_1.nombre"),
      titulo: "Descomp",
      color: "#05A0EE",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.DESCOMP_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.DESCOMP_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.DESCOMP_avif,
      extras: t("Descomp_1.extras"),
      generalidades: t("Descomp_1.generalidades"),
      composicion: [
        {
          componente: t("Descomp_1.composicion.1.componente"),
          porcetanje: "30.00%",
        },
        {
          componente: t("Descomp_1.composicion.2.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("Descomp_1.composicion.3.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("Descomp_1.composicion.4.componente"),
          porcetanje: "50.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Humi_K80_1.nombre"),
      titulo: "Humi K80",
      color: "#05A0EE",
      presentacioneImages: [CORRECTORES_DE_AGUA_Y_SUELO_img.BOLSA_HUMI_K80_png],
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.HUMI_K80_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.HUMI_K80_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.HUMI_K80_avif,
      extras: t("Humi_K80_1.extras"),
      generalidades: t("Humi_K80_1.generalidades"),
      composicion: [
        {
          componente: t("Humi_K80_1.composicion.1.componente"),
          porcetanje: "65.00%",
        },
        {
          componente: t("Humi_K80_1.composicion.2.componente"),
          porcetanje: "15.00%",
        },
        {
          componente: t("Humi_K80_1.composicion.3.componente"),
          porcetanje: "10.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "1 Kg",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "25 Kg",
        },
      ],
    },
    // Coadyuvantes
    {
      nombre: t("Spander_100_SL_1.nombre"),
      titulo: "Spander 100 SL",
      color: "#46947D",
      png: COADYUVANTES_img.PANDER_100_SL_png,
      webp: COADYUVANTES_img.PANDER_100_SL_webp,
      avif: COADYUVANTES_img.PANDER_100_SL_avif,
      extras: t("Spander_100_SL_1.extras"),
      generalidades: t("Spander_100_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Spander_100_SL_1.composicion.1.componente"),
          porcetanje: "95.00%",
        },
        {
          componente: t("Spander_100_SL_1.composicion.2.componente"),
          porcetanje: "3.00%",
        },
        {
          componente: t("Spander_100_SL_1.composicion.3.componente"),
          porcetanje: "2.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_250CC,
          tituloPresentacion: "250cc",
        },
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("W-30_SL_1.nombre"),
      titulo: "W-30 SL",
      color: "#46947D",
      png: COADYUVANTES_img.W_30_SL_png,
      webp: COADYUVANTES_img.W_30_SL_webp,
      avif: COADYUVANTES_img.W_30_SL_avif,
      extras: t("W-30_SL_1.extras"),
      generalidades: t("W-30_SL_1.generalidades"),
      composicion: [
        {
          componente: t("W-30_SL_1.composicion.1.componente"),
          porcetanje: "28.3%",
        },
        {
          componente: t("W-30_SL_1.composicion.2.componente"),
          porcetanje: "1.7%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_250CC,
          tituloPresentacion: "250cc",
        },
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Film_Kover_83_EC_1.nombre"),
      titulo: "Film Kover 83 EC",
      color: "#46947D",
      png: COADYUVANTES_img.FILM_KOVER_83_EC_png,
      webp: COADYUVANTES_img.FILM_KOVER_83_EC_webp,
      avif: COADYUVANTES_img.FILM_KOVER_83_EC_avif,
      extras: t("Film_Kover_83_EC_1.extras"),
      generalidades: t("Film_Kover_83_EC_1.generalidades"),
      composicion: [
        {
          componente: t("Film_Kover_83_EC_1.composicion.1.componente"),
          porcetanje: "83.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Ps_-_Max_1.nombre"),
      titulo: "Ps - Max",
      color: "#46947D",
      png: COADYUVANTES_img.PS_MAX_png,
      webp: COADYUVANTES_img.PS_MAX_webp,
      avif: COADYUVANTES_img.PS_MAX_avif,
      extras: t("Ps_-_Max_1.extras"),
      generalidades: t("Ps_-_Max_1.generalidades"),
      composicion: [
        {
          componente: t("Ps_-_Max_1.composicion.1.componente"),
          porcetanje: "35.00%",
        },
        {
          componente: t("Ps_-_Max_1.composicion.2.componente"),
          porcetanje: "3.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Adher_30_Sl_1.nombre"),
      titulo: "Adher 30 Sl",
      color: "#46947D",
      png: COADYUVANTES_img.ADHER_30_SL_png,
      webp: COADYUVANTES_img.ADHER_30_SL_webp,
      avif: COADYUVANTES_img.ADHER_30_SL_avif,
      extras: t("Adher_30_Sl_1.extras"),
      generalidades: t("Adher_30_Sl_1.generalidades"),
      composicion: [
        {
          componente: t("Adher_30_Sl_1.composicion.1.componente"),
          porcetanje: "20.00%",
        },
        {
          componente: t("Adher_30_Sl_1.composicion.2.componente"),
          porcetanje: "10.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Surfaplus_28.7_SL_1.nombre"),
      titulo: "Surfaplus 28.7 SL",
      color: "#46947D",
      png: COADYUVANTES_img.SURFAPLUS_28_png,
      webp: COADYUVANTES_img.SURFAPLUS_28_webp,
      avif: COADYUVANTES_img.SURFAPLUS_28_avif,
      extras: t("Surfaplus_28.7_SL_1.extras"),
      generalidades: t("Surfaplus_28.7_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Surfaplus_28.7_SL_1.composicion.1.componente"),
          porcetanje: "27.00%",
        },
        {
          componente: t("Surfaplus_28.7_SL_1.composicion.2.componente"),
          porcetanje: "1.70%",
        },
        {
          componente: t("Surfaplus_28.7_SL_1.composicion.3.componente"),
          porcetanje: "71.3%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Potenzoil_85_SL_1.nombre"),
      titulo: "Potenzoil 85 SL",
      color: "#46947D",
      png: COADYUVANTES_img.POTENZOIL_85_SL_png,
      webp: COADYUVANTES_img.POTENZOIL_85_SL_webp,
      avif: COADYUVANTES_img.POTENZOIL_85_SL_avif,
      extras: t("Potenzoil_85_SL_1.extras"),
      generalidades: t("Potenzoil_85_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Potenzoil_85_SL_1.composicion.1.componente"),
          porcetanje: "85.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Kapser_88_SL_1.nombre"),
      titulo: "Kapser 88 SL",
      color: "#46947D",
      png: COADYUVANTES_img.KAPSER_88_SL_png,
      webp: COADYUVANTES_img.KAPSER_88_SL_webp,
      avif: COADYUVANTES_img.KAPSER_88_SL_avif,
      extras: t("Kapser_88_SL_1.extras"),
      generalidades: t("Kapser_88_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Kapser_88_SL_1.composicion.1.componente"),
          porcetanje: "88.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Dew_Max_90_SL_1.nombre"),
      titulo: "Dew - Max 90 SL",
      color: "#46947D",
      png: COADYUVANTES_img.DEW_MAX_90_SL_png,
      webp: COADYUVANTES_img.DEW_MAX_90_SL_webp,
      avif: COADYUVANTES_img.DEW_MAX_90_SL_avif,
      extras: t("Dew_Max_90_SL_1.extras"),
      generalidades: t("Dew_Max_90_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Dew_Max_90_SL_1.composicion.1.componente"),
          porcetanje: "90.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1L,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "5 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "200 L",
        },
      ],
    },
    {
      nombre: t("Emurall_85_SL_1.nombre"),
      titulo: "Emurall 85 SL",
      color: "#46947D",
      png: COADYUVANTES_img.EMURALL_85_SL_png,
      webp: COADYUVANTES_img.EMURALL_85_SL_webp,
      avif: COADYUVANTES_img.EMURALL_85_SL_avif,
      extras: t("Emurall_85_SL_1.extras"),
      generalidades: t("Emurall_85_SL_1.generalidades"),
      composicion: [
        {
          componente: t("Emurall_85_SL_1.composicion.1.componente"),
          porcetanje: "85.00%",
        },
        {
          componente: t("Emurall_85_SL_1.composicion.2.componente"),
          porcetanje: "15.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_5L,
          tituloPresentacion: "4 L",
        },
        {
          imgPresentacion: p_20L,
          tituloPresentacion: "20 L",
        },
        {
          imgPresentacion: p_200L,
          tituloPresentacion: "60 L",
        },
      ],
    },
    // HIDROSOLUBLES
    {
      nombre: t("N_Potenz_1.nombre"),
      titulo: "N-Potenz",
      color: "#15337D",
      png: HIDROSOLUBLES_img.N_POTENZ_png,
      webp: HIDROSOLUBLES_img.N_POTENZ_webp,
      avif: HIDROSOLUBLES_img.N_POTENZ_avif,
      extras: t("N_Potenz_1.extras"),
      generalidades: t("N_Potenz_1.generalidades"),
      composicion: [
        {
          componente: t("N_Potenz_1.composicion.1.componente"),
          porcetanje: "40.00%",
        },
        {
          componente: t("N_Potenz_1.composicion.2.componente"),
          porcetanje: "4.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "1 Kg",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "5 Kg",
        },
      ],
    },
    {
      nombre: t("Hidrobor_1.nombre"),
      titulo: "Hidrobor",
      color: "#15337D",
      png: HIDROSOLUBLES_img.HIDROBOR_png,
      webp: HIDROSOLUBLES_img.HIDROBOR_webp,
      avif: HIDROSOLUBLES_img.HIDROBOR_avif,
      extras: t("Hidrobor_1.extras"),
      generalidades: t("Hidrobor_1.generalidades"),
      composicion: [
        {
          componente: t("Hidrobor_1.composicion.1.componente"),
          porcetanje: "44.80%",
        },
        {
          componente: t("Hidrobor_1.composicion.2.componente"),
          porcetanje: "9.96%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "1 Kg",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "1 Kg",
        },
      ],
    },
    {
      nombre: t("10-40-10+AH+EM_1.nombre"),
      titulo: "10-40-10+AH+EM",
      color: "#15337D",
      png: HIDROSOLUBLES_img.HI_10_40_10_AH_EM_png,
      webp: HIDROSOLUBLES_img.HI_10_40_10_AH_EM_webp,
      avif: HIDROSOLUBLES_img.HI_10_40_10_AH_EM_avif,
      extras: t("10-40-10+AH+EM_1.extras"),
      generalidades: t("10-40-10+AH+EM_1.generalidades"),
      composicion: [
        {
          componente: t("10-40-10+AH+EM_1.composicion.1.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.2.componente"),
          porcetanje: "40.00%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.3.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.4.componente"),
          porcetanje: "0.02%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.5.componente"),
          porcetanje: "0.01%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.6.componente"),
          porcetanje: "0.10%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.7.componente"),
          porcetanje: "0.5%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.8.componente"),
          porcetanje: "0.01%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.9.componente"),
          porcetanje: "0.0002%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.10.componente"),
          porcetanje: "0.002%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.11.componente"),
          porcetanje: "0.05%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.12.componente"),
          porcetanje: "0.0025%",
        },
        {
          componente: t("10-40-10+AH+EM_1.composicion.13.componente"),
          porcetanje: "0.011%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "1 Kg",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "25 Kg",
        },
      ],
    },
    {
      nombre: t("20-20-20_1.nombre"),
      titulo: "20-20-20",
      color: "#15337D",
      png: HIDROSOLUBLES_img.HI_20_20_20_png,
      webp: HIDROSOLUBLES_img.HI_20_20_20_webp,
      avif: HIDROSOLUBLES_img.HI_20_20_20_avif,
      extras: t("20-20-20_1.extras"),
      generalidades: t("20-20-20_1.generalidades"),
      composicion: [
        {
          componente: t("20-20-20_1.composicion.1.componente"),
          porcetanje: "20.00%",
        },
        {
          componente: t("20-20-20_1.composicion.2.componente"),
          porcetanje: "20.00%",
        },
        {
          componente: t("20-20-20_1.composicion.3.componente"),
          porcetanje: "20.00%",
        },
        {
          componente: t("20-20-20_1.composicion.4.componente"),
          porcetanje: "0.01%",
        },
        {
          componente: t("20-20-20_1.composicion.5.componente"),
          porcetanje: "0.05%",
        },
        {
          componente: t("20-20-20_1.composicion.6.componente"),
          porcetanje: "0.125%",
        },
        {
          componente: t("20-20-20_1.composicion.7.componente"),
          porcetanje: "0.01%",
        },
        {
          componente: t("20-20-20_1.composicion.8.componente"),
          porcetanje: "0.01%",
        },
        {
          componente: t("20-20-20_1.composicion.9.componente"),
          porcetanje: "0.10%",
        },
        {
          componente: t("20-20-20_1.composicion.10.componente"),
          porcetanje: "0.02%",
        },
        {
          componente: t("20-20-20_1.composicion.11.componente"),
          porcetanje: "0.10%",
        },
        {
          componente: t("20-20-20_1.composicion.12.componente"),
          porcetanje: "1.50%",
        },
        {
          componente: t("20-20-20_1.composicion.13.componente"),
          porcetanje: "0.20%",
        },
        {
          componente: t("20-20-20_1.composicion.14.componente"),
          porcetanje: "0.10%",
        },
        {
          componente: t("20-20-20_1.composicion.15.componente"),
          porcetanje: "0.0025%",
        },
        {
          componente: t("20-20-20_1.composicion.16.componente"),
          porcetanje: "0.0020%",
        },
        {
          componente: t("20-20-20_1.composicion.17.componente"),
          porcetanje: "0.0020%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "1 L",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "5 L",
        },
      ],
    },
    {
      nombre: t("10-10-40+AH+EM_1.nombre"),
      titulo: "10-10-40+AH+EM",
      color: "#15337D",
      png: HIDROSOLUBLES_img.HI_10_10_40_AH_EM_png,
      webp: HIDROSOLUBLES_img.HI_10_10_40_AH_EM_webp,
      avif: HIDROSOLUBLES_img.HI_10_10_40_AH_EM_avif,
      extras: t("10-10-40+AH+EM_1.extras"),
      generalidades: t("10-10-40+AH+EM_1.generalidades"),
      composicion: [
        {
          componente: t("10-10-40+AH+EM_1.composicion.1.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.2.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.3.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.4.componente"),
          porcetanje: "0.02%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.5.componente"),
          porcetanje: "0.01%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.6.componente"),
          porcetanje: "0.10%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.7.componente"),
          porcetanje: "0.5%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.8.componente"),
          porcetanje: "0.01%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.9.componente"),
          porcetanje: "0.0002%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.10.componente"),
          porcetanje: "0.002%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.11.componente"),
          porcetanje: "0.05%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.12.componente"),
          porcetanje: "0.0025%",
        },
        {
          componente: t("10-10-40+AH+EM_1.composicion.13.componente"),
          porcetanje: "0.011%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "1 Kg",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "25 Kg",
        },
      ],
    },
    {
      nombre: t("K_Humus_1.nombre"),
      titulo: "K Humus",
      color: "#15337D",
      png: HIDROSOLUBLES_img.K_HUMES_png,
      webp: HIDROSOLUBLES_img.K_HUMES_webp,
      avif: HIDROSOLUBLES_img.K_HUMES_avif,
      extras: t("K_Humus_1.extras"),
      generalidades: t("K_Humus_1.generalidades"),
      composicion: [
        {
          componente: t("K_Humus_1.composicion.1.componente"),
          porcetanje: "10.10%",
        },
        {
          componente: t("K_Humus_1.composicion.2.componente"),
          porcetanje: "2.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "1 Kg",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "5 Kg",
        },
      ],
    },
    {
      nombre: t("Potenz_Blend_1.nombre"),
      titulo: "Potenz Blend",
      color: "#15337D",
      png: HIDROSOLUBLES_img.POTENZ_BLEND_png,
      webp: HIDROSOLUBLES_img.POTENZ_BLEND_webp,
      avif: HIDROSOLUBLES_img.POTENZ_BLEND_avif,
      extras: t("Potenz_Blend_1.extras"),
      generalidades: t("Potenz_Blend_1.generalidades"),
      composicion: [
        {
          componente: t("Potenz_Blend_1.composicion.1.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("Potenz_Blend_1.composicion.2.componente"),
          porcetanje: "64.00%",
        },
        {
          componente: t("Potenz_Blend_1.composicion.3.componente"),
          porcetanje: "10.00%",
        },
        {
          componente: t("Potenz_Blend_1.composicion.4.componente"),
          porcetanje: "8.00%",
        },
      ],
      presentaciones: [
        {
          imgPresentacion: p_1KG,
          tituloPresentacion: "1 Kg",
        },
        {
          imgPresentacion: p_25KG,
          tituloPresentacion: "5 Kg",
        },
      ],
    },
  ];

  const [resultados, setResultados] = useState([]);

  useEffect(() => {
    const resultadosEncontrados = productos.filter((producto) =>
      producto.titulo.toLowerCase().includes(nombre_producto.toLowerCase())
    );
    resultadosEncontrados[0].arrayPng = [resultadosEncontrados[0].png];
    if (
      resultadosEncontrados[0].presentacioneImages &&
      resultadosEncontrados[0].presentacioneImages.length > 0
    ) {
      resultadosEncontrados[0].arrayPng = [
        ...resultadosEncontrados[0].arrayPng,
        ...resultadosEncontrados[0].presentacioneImages,
      ];
    }
    setResultados(resultadosEncontrados);
  }, [nombre_producto, language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [nombre_producto]);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [imageIndex, setImageIndex] = useState(0);

  const changeImage = (index) => {
    setImageIndex(index);
  };

  return (
    <div>
      <Header isSticky={true} />
      <div className="container" style={{ marginTop: 100 }}>
        {resultados.map((producto, index) => (
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
            key={index}
          >
            <div className="col-12 col-md-6">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <img
                  className="img-fluid m-3"
                  style={{ width: "35%" }}
                  src={producto.imagen}
                  alt="producto"
                /> */}
                <Fade
                  triggerOnce
                  direction="left"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <picture className="img-fluid m-3" style={{ width: "35%" }}>
                    {/* <source
                      className="img-fluid"
                      srcSet={producto.webp}
                      type="image/webp"
                    />
                    <source
                      className="img-fluid"
                      srcSet={producto.avif}
                      type="image/avif"
                    /> */}

                    <img
                      className="img-fluid"
                      src={producto.arrayPng[imageIndex]} // Reemplaza con la ruta de la imagen del producto
                      alt={producto.nombre}
                    />
                  </picture>
                </Fade>

                <div className="displayImage">
                  {producto.arrayPng.map((image, indexImage) => (
                    <img
                      onClick={() => changeImage(indexImage)}
                      className="boxImage"
                      src={image}
                      alt={producto.nombre}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div>
                <Fade triggerOnce direction="down">
                  <h1 className="titulo_producto bold">
                    {producto.nombre.toUpperCase()}
                  </h1>
                </Fade>

                <Fade triggerOnce direction="left">
                  <h5
                    className="titulo_extras mt-3 mb-3 bold"
                    style={{ color: `${producto.color}`, fontWeight: "bolder" }}
                  >
                    {producto.extras.toUpperCase()}
                  </h5>
                </Fade>

                <h5 className="titulo_Generalidades bold">Generalidades</h5>
                <Slide triggerOnce direction="right" delay={100}>
                  <p className="contenido_de_generalidades light">
                    {producto.generalidades}
                  </p>
                </Slide>

                <div className="row d-flex">
                  <h5 className="titulo_composicion bold">Composición</h5>
                  {producto.composicion.map((sub_componete, index) => (
                    <div className="col-md-3 col-4 d-flex flex-column contenido_de_componentes">
                      <Fade triggerOnce direction="left">
                        <span className="porcentaje_del_componente light">
                          {sub_componete.porcetanje}
                        </span>
                      </Fade>
                      <Fade triggerOnce direction="left">
                        <span className="titulo_de_conponente bold">
                          {sub_componete.componente}
                        </span>
                      </Fade>
                    </div>
                  ))}
                </div>
                <div className="row">
                  <h5 className="titulo_presentacion bold">Presentaciones</h5>

                  {producto.presentaciones.map((sub_presentacion, index) => (
                    <div
                      className="col-md-2 col-2 d-flex flex-column align-items-start justify-content-center"
                      // style={{ width: "102px", height: "140px" }}
                      key={index}
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <Fade triggerOnce direction="left">
                          <img
                            className="img-fluid"
                            src={sub_presentacion.imgPresentacion}
                            alt={sub_presentacion.tituloPresentacion}
                          />
                        </Fade>
                        <Fade triggerOnce direction="left">
                          <p className="bold">
                            {sub_presentacion.tituloPresentacion}
                          </p>
                        </Fade>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Productos />

      <Footer />
    </div>
  );
}
