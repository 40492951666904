// [INICIO] agrinutientes
import AGRINUTRIENTE_Zn_b_png from "./../assets/img/Catalogo_img/AGRINUTRIENTES/AGRINUTRIENTE_Zn+B.png";
import AGRINUTRIENTE_Zn_b_webp from "./../assets/img/Catalogo_img/AGRINUTRIENTES/AGRINUTRIENTE_Zn+B.webp";
import AGRINUTRIENTE_Zn_b_avif from "./../assets/img/Catalogo_img/AGRINUTRIENTES/agrinutriente-zn-b.avif";
import AGRINUTRIENTE_Ca_b_png from "./../assets/img/Catalogo_img/AGRINUTRIENTES/AGRINUTRIENTE_Ca+B.png";
import AGRINUTRIENTE_Ca_b_webp from "./../assets/img/Catalogo_img/AGRINUTRIENTES/AGRINUTRIENTE_Ca+B.webp";
import AGRINUTRIENTE_Ca_b_avif from "./../assets/img/Catalogo_img/AGRINUTRIENTES/agrinutriente-ca-b.avif";
import AGRINUTRIENTE_17_17_17_png from "./../assets/img/Catalogo_img/AGRINUTRIENTES/AGRINUTRIENTE_17-17-17.png";
import AGRINUTRIENTE_17_17_17_webp from "./../assets/img/Catalogo_img/AGRINUTRIENTES/AGRINUTRIENTE_17-17-17.webp";
import AGRINUTRIENTE_17_17_17_avif from "./../assets/img/Catalogo_img/AGRINUTRIENTES/agrinutriente-17-17-17.avif";
import AGRINUTRIENTE_B_png from "./../assets/img/Catalogo_img/AGRINUTRIENTES/AGRINUTRIENTE_B.png";
import AGRINUTRIENTE_B_webp from "./../assets/img/Catalogo_img/AGRINUTRIENTES/AGRINUTRIENTE_B.webp";
import AGRINUTRIENTE_B_avif from "./../assets/img/Catalogo_img/AGRINUTRIENTES/agrinutriente-b.avif";
// [FIN] agrinutientes

// [INICIO] BIOESTIMULANTES
import ATP_K_png from "./../assets/img/Catalogo_img/BIOESTIMULANTES/1_ATP-K.png";
import ATP_K_webp from "./../assets/img/Catalogo_img/BIOESTIMULANTES/1_ATP-K.webp";
import ATP_K_avif from "./../assets/img/Catalogo_img/BIOESTIMULANTES/1-atp-k.avif";
import MASTER_FLOWER_png from "./../assets/img/Catalogo_img/BIOESTIMULANTES/2_MASTER_FLOWER.png";
import MASTER_FLOWER_webp from "./../assets/img/Catalogo_img/BIOESTIMULANTES/2_MASTER_FLOWER.webp";
import MASTER_FLOWER_avif from "./../assets/img/Catalogo_img/BIOESTIMULANTES/2-master-flower.avif";
import POTENZAMIN_50_png from "./../assets/img/Catalogo_img/BIOESTIMULANTES/3_POTENZAMIN_50.png";
import POTENZAMIN_50_webp from "./../assets/img/Catalogo_img/BIOESTIMULANTES/3_POTENZAMIN_50.webp";
import POTENZAMIN_50_avif from "./../assets/img/Catalogo_img/BIOESTIMULANTES/3-potenzamin-50.avif";
import ROOT_DRIVE_png from "./../assets/img/Catalogo_img/BIOESTIMULANTES/4_ROOT_DRIVE.png";
import ROOT_DRIVE_webp from "./../assets/img/Catalogo_img/BIOESTIMULANTES/4_ROOT_DRIVE.webp";
import ROOT_DRIVE_avif from "./../assets/img/Catalogo_img/BIOESTIMULANTES/4-root-drive.avif";
import ALPROMAR_30_SL_png from "./../assets/img/Catalogo_img/BIOESTIMULANTES/5_ALPROMAR_30_SL.png";
import ALPROMAR_30_SL_webp from "./../assets/img/Catalogo_img/BIOESTIMULANTES/5_ALPROMAR_30_SL.webp";
import ALPROMAR_30_SL_avif from "./../assets/img/Catalogo_img/BIOESTIMULANTES/5-alpromar-30-sl.avif";
import AMINOACIDOS_80_png from "./../assets/img/Catalogo_img/BIOESTIMULANTES/6_AMINOACIDOS_80.png";
import AMINOACIDOS_80_webp from "./../assets/img/Catalogo_img/BIOESTIMULANTES/6_AMINOACIDOS_80.webp";
import AMINOACIDOS_80_avif from "./../assets/img/Catalogo_img/BIOESTIMULANTES/6-aminoacidos-80.avif";
import MASTER_15_15_15_png from "./../assets/img/Catalogo_img/BIOESTIMULANTES/7_15-15-15_MASTER.png";
import MASTER_15_15_15_webp from "./../assets/img/Catalogo_img/BIOESTIMULANTES/7_15-15-15_MASTER.webp";
import MASTER_15_15_15_avif from "./../assets/img/Catalogo_img/BIOESTIMULANTES/7-15-15-15-master.avif";
import MICROAMIN_png from "./../assets/img/Catalogo_img/BIOESTIMULANTES/8_MICROAMIN.png";
import MICROAMIN_webp from "./../assets/img/Catalogo_img/BIOESTIMULANTES/8_MICROAMIN.webp";
import MICROAMIN_avif from "./../assets/img/Catalogo_img/BIOESTIMULANTES/8-microamin.avif";
import BAG_ALRPOMAR_100_png from "./../assets/img/Catalogo_img/BIOESTIMULANTES/BOLSA-ALPROMAR.png";
import ALRPOMAR_100_png from "./../assets/img/Catalogo_img/BIOESTIMULANTES/9_ALRPOMAR_100.png";
import ALRPOMAR_100_webp from "./../assets/img/Catalogo_img/BIOESTIMULANTES/9_ALRPOMAR_100.webp";
import ALRPOMAR_100_avif from "./../assets/img/Catalogo_img/BIOESTIMULANTES/9-alrpomar-100.avif";
// [FIN] BIOESTIMULANTES

// [INICIO] BIOPROTECTORES
import FANGE_20_png from "./../assets/img/Catalogo_img/BIOPROTECTORES/1_FANGE_20_SL.png";
import FANGE_20_webp from "./../assets/img/Catalogo_img/BIOPROTECTORES/1_FANGE_20_SL.webp";
import FANGE_20_avif from "./../assets/img/Catalogo_img/BIOPROTECTORES/1-fange-20-sl.avif";
import K_OLEO_png from "./../assets/img/Catalogo_img/BIOPROTECTORES/2_K_OLEO.png";
import K_OLEO_webp from "./../assets/img/Catalogo_img/BIOPROTECTORES/2_K_OLEO.webp";
import K_OLEO_avif from "./../assets/img/Catalogo_img/BIOPROTECTORES/2-k-oleo.avif";
import OMICRON_BIO_15_SL_png from "./../assets/img/Catalogo_img/BIOPROTECTORES/3_OMICRON_BIO_15_SL.png";
import OMICRON_BIO_15_SL_webp from "./../assets/img/Catalogo_img/BIOPROTECTORES/3_OMICRON_BIO_15_SL.webp";
import OMICRON_BIO_15_SL_avif from "./../assets/img/Catalogo_img/BIOPROTECTORES/3-omicron-bio-15-sl.avif";
import OMICRON_BG_2000_png from "./../assets/img/Catalogo_img/BIOPROTECTORES/4_OMICRON_BG_2000.png";
import OMICRON_BG_2000_webp from "./../assets/img/Catalogo_img/BIOPROTECTORES/4_OMICRON_BG_2000.webp";
import OMICRON_BG_2000_avif from "./../assets/img/Catalogo_img/BIOPROTECTORES/4-omicron-bg-2000.avif";
import POTENZ_NEEM_png from "./../assets/img/Catalogo_img/BIOPROTECTORES/5_POTENZ_NEEM.png";
import POTENZ_NEEM_webp from "./../assets/img/Catalogo_img/BIOPROTECTORES/5_POTENZ_NEEM.webp";
import POTENZ_NEEM_avif from "./../assets/img/Catalogo_img/BIOPROTECTORES/5-potenz-neem.avif";
import KARAJO_EC_png from "./../assets/img/Catalogo_img/BIOPROTECTORES/6_KARAJO_EC.png";
import KARAJO_EC_webp from "./../assets/img/Catalogo_img/BIOPROTECTORES/6_KARAJO_EC.webp";
import KARAJO_EC_avif from "./../assets/img/Catalogo_img/BIOPROTECTORES/6-karajo-ec.avif";
import POTENZSIL_png from "./../assets/img/Catalogo_img/BIOPROTECTORES/7_POTENZSIL.png";
import POTENZSIL_webp from "./../assets/img/Catalogo_img/BIOPROTECTORES/7_POTENZSIL.webp";
import POTENZSIL_avif from "./../assets/img/Catalogo_img/BIOPROTECTORES/7-potenzsil.avif";
import LANGE_LEBEN_png from "./../assets/img/Catalogo_img/BIOPROTECTORES/8_LANGE_LEBEN.png";
import LANGE_LEBEN_webp from "./../assets/img/Catalogo_img/BIOPROTECTORES/8_LANGE_LEBEN.webp";
import LANGE_LEBEN_avif from "./../assets/img/Catalogo_img/BIOPROTECTORES/8-lange-leben.avif";
import KARANJA_85_SL_png from "./../assets/img/Catalogo_img/BIOPROTECTORES/9_KARANJA_85_SL.png";
import KARANJA_85_SL_webp from "./../assets/img/Catalogo_img/BIOPROTECTORES/9_KARANJA_85_SL.webp";
import KARANJA_85_SL_avif from "./../assets/img/Catalogo_img/BIOPROTECTORES/9-karanja-85-sl.avif";
import K_OLEO_CANELA_png from "./../assets/img/Catalogo_img/BIOPROTECTORES/10_K_OLEO+CANELA.png";
import K_OLEO_CANELA_webp from "./../assets/img/Catalogo_img/BIOPROTECTORES/10_K_OLEO+CANELA.webp";
import K_OLEO_CANELA_avif from "./../assets/img/Catalogo_img/BIOPROTECTORES/10-k-oleo-canela.avif";
// [FIN] BIOPROTECTORES

// [INICIO] COADYUVANTES
import PANDER_100_SL_png from "./../assets/img/Catalogo_img/COADYUVANTES/1_SPANDER_100_SL.png";
import PANDER_100_SL_webp from "./../assets/img/Catalogo_img/COADYUVANTES/1_SPANDER_100_SL.webp";
import PANDER_100_SL_avif from "./../assets/img/Catalogo_img/COADYUVANTES/1-spander-100-sl.avif";
import W_30_SL_png from "./../assets/img/Catalogo_img/COADYUVANTES/2_W_30_SL.png";
import W_30_SL_webp from "./../assets/img/Catalogo_img/COADYUVANTES/2_W_30_SL.webp";
import W_30_SL_avif from "./../assets/img/Catalogo_img/COADYUVANTES/2-w-30-sl.avif";
import FILM_KOVER_83_EC_png from "./../assets/img/Catalogo_img/COADYUVANTES/3_FILM_KOVER_83_EC.png";
import FILM_KOVER_83_EC_webp from "./../assets/img/Catalogo_img/COADYUVANTES/3_FILM_KOVER_83_EC.webp";
import FILM_KOVER_83_EC_avif from "./../assets/img/Catalogo_img/COADYUVANTES/3-film-kover-83-ec.avif";
import PS_MAX_png from "./../assets/img/Catalogo_img/COADYUVANTES/4_PS_MAX.png";
import PS_MAX_webp from "./../assets/img/Catalogo_img/COADYUVANTES/4_PS_MAX.webp";
import PS_MAX_avif from "./../assets/img/Catalogo_img/COADYUVANTES/4-ps-max.avif";
import ADHER_30_SL_png from "./../assets/img/Catalogo_img/COADYUVANTES/5_ADHER_30_SL.png";
import ADHER_30_SL_webp from "./../assets/img/Catalogo_img/COADYUVANTES/5_ADHER_30_SL.webp";
import ADHER_30_SL_avif from "./../assets/img/Catalogo_img/COADYUVANTES/5-adher-30-sl.avif";
import SURFAPLUS_28_png from "./../assets/img/Catalogo_img/COADYUVANTES/6_SURFAPLUS_28.7_SL.png";
import SURFAPLUS_28_webp from "./../assets/img/Catalogo_img/COADYUVANTES/6_SURFAPLUS_28.7_SL.webp";
import SURFAPLUS_28_avif from "./../assets/img/Catalogo_img/COADYUVANTES/6-surfaplus-28-7-sl.avif";
import POTENZOIL_85_SL_png from "./../assets/img/Catalogo_img/COADYUVANTES/7_POTENZOIL_85_SL.png";
import POTENZOIL_85_SL_webp from "./../assets/img/Catalogo_img/COADYUVANTES/7_POTENZOIL_85_SL.webp";
import POTENZOIL_85_SL_avif from "./../assets/img/Catalogo_img/COADYUVANTES/7-potenzoil-85-sl.avif";
import KAPSER_88_SL_png from "./../assets/img/Catalogo_img/COADYUVANTES/8_KAPSER_88_SL.png";
import KAPSER_88_SL_webp from "./../assets/img/Catalogo_img/COADYUVANTES/8_KAPSER_88_SL.webp";
import KAPSER_88_SL_avif from "./../assets/img/Catalogo_img/COADYUVANTES/8-kapser-88-sl.avif";
import DEW_MAX_90_SL_png from "./../assets/img/Catalogo_img/COADYUVANTES/9_DEW_MAX_90_SL.png";
import DEW_MAX_90_SL_webp from "./../assets/img/Catalogo_img/COADYUVANTES/9_DEW_MAX_90_SL.webp";
import DEW_MAX_90_SL_avif from "./../assets/img/Catalogo_img/COADYUVANTES/9-dew-max-90-sl.avif";
import EMURALL_85_SL_png from "./../assets/img/Catalogo_img/COADYUVANTES/10_EMURALL_85_SL.png";
import EMURALL_85_SL_webp from "./../assets/img/Catalogo_img/COADYUVANTES/10_EMURALL_85_SL.webp";
import EMURALL_85_SL_avif from "./../assets/img/Catalogo_img/COADYUVANTES/10-emurall-85-sl.avif";
// [FIN] COADYUVANTES

// [INICIO] ESPECIALIZADOS
import MEISTER_PAPA_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/1_MEISTER_PAPA.png";
import MEISTER_PAPA_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/1_MEISTER_PAPA.webp";
import MEISTER_PAPA_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/1-meister-papa.avif";
import MEISTER_MAIZ_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/2_MEISTER_MAIZ.png";
import MEISTER_MAIZ_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/2_MEISTER_MAIZ.webp";
import MEISTER_MAIZ_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/2-meister-maiz.avif";
import MEISTER_FRIJOL_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/3_MEISTER_FRIJOL.png";
import MEISTER_FRIJOL_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/3_MEISTER_FRIJOL.webp";
import MEISTER_FRIJOL_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/3-meister-frijol.avif";
import MEISTER_CAFE_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/4_MEISTER_CAFE.png";
import MEISTER_CAFE_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/4_MEISTER_CAFE.webp";
import MEISTER_CAFE_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/4-meister-cafe.avif";
import MEISTER_AJONJOLI_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/5_MEISTER_AJONJOLI.png";
import MEISTER_AJONJOLI_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/5_MEISTER_AJONJOLI.webp";
import MEISTER_AJONJOLI_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/5-meister-ajonjoli.avif";
import MEISTER_PLUS_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/6_MEISTER_PLUS.png";
import MEISTER_PLUS_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/6_MEISTER_PLUS.webp";
import MEISTER_PLUS_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/6-meister-plus.avif";
import ES_8_24_0_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/7_8-24-0.png";
import ES_8_24_0_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/7_8-24-0.webp";
import ES_8_24_0_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/7-8-24-0.avif";
import HUMATO_DE_CALCIO_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/8_HUMATO_DE_CALCIO.png";
import HUMATO_DE_CALCIO_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/8_HUMATO_DE_CALCIO.webp";
import HUMATO_DE_CALCIO_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/8-humato-de-calcio.avif";
import HUMATO_DE_MAGNESIO_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/9_HUMATO_DE_MAGNESIO.png";
import HUMATO_DE_MAGNESIO_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/9_HUMATO_DE_MAGNESIO.webp";
import HUMATO_DE_MAGNESIO_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/9-humato-de-magnesio.avif";
import POTENZ_K_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/10_POTENZ-K.png";
import POTENZ_K_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/10_POTENZ-K.webp";
import POTENZ_K_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/10-potenz-k.avif";
import NITRATO_DE_ZINC_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/11_NITRATO_DE_ZINC.png";
import NITRATO_DE_ZINC_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/11_NITRATO_DE_ZINC.webp";
import NITRATO_DE_ZINC_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/11-nitrato-de-zinc.avif";
import POTENZ_BRIXX_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/12_POTENZ_BRIXX.png";
import POTENZ_BRIXX_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/12_POTENZ_BRIXX.webp";
import POTENZ_BRIXX_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/12-potenz-brixx.avif";
import MAS_CANA_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/13_MAS_CANA.png";
import MAS_CANA_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/13_MAS_CANA.webp";
import MAS_CANA_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/13_MAS_CANA.avif";
import FOST_ALEXIN_K_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/14_FOST_ALEXIN-K.png";
import FOST_ALEXIN_K_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/14_FOST_ALEXIN-K.webp";
import FOST_ALEXIN_K_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/14-fost-alexin-k.avif";
import MICROPLUS_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/15_MICROPLUS.png";
import MICROPLUS_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/15_MICROPLUS.webp";
import MICROPLUS_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/15-microplus.avif";
import QUELATT_Fe_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/16_QUELATT_Fe.png";
import QUELATT_Fe_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/16_QUELATT_Fe.webp";
import QUELATT_Fe_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/16-quelatt-fe.avif";
import ES_10_34_0_png from "./../assets/img/Catalogo_img/ESPECIALIZADOS/17_10-34-0.png";
import ES_10_34_0_webp from "./../assets/img/Catalogo_img/ESPECIALIZADOS/17_10-34-0.webp";
import ES_10_34_0_avif from "./../assets/img/Catalogo_img/ESPECIALIZADOS/17-10-34-0.avif";
// [FIN] ESPECIALIZADOS

// [INICIO] HIDROSOLUBLES
import N_POTENZ_png from "./../assets/img/Catalogo_img/HIDROSOLUBLES/1_N-POTENZ.png";
import N_POTENZ_webp from "./../assets/img/Catalogo_img/HIDROSOLUBLES/1_N-POTENZ.webp";
import N_POTENZ_avif from "./../assets/img/Catalogo_img/HIDROSOLUBLES/1-n-potenz.avif";
import HIDROBOR_png from "./../assets/img/Catalogo_img/HIDROSOLUBLES/2_HIDROBOR.png";
import HIDROBOR_webp from "./../assets/img/Catalogo_img/HIDROSOLUBLES/2_HIDROBOR.webp";
import HIDROBOR_avif from "./../assets/img/Catalogo_img/HIDROSOLUBLES/2-hidrobor.avif";
import HI_10_40_10_AH_EM_png from "./../assets/img/Catalogo_img/HIDROSOLUBLES/3_10-40-10+AH+EM.png";
import HI_10_40_10_AH_EM_webp from "./../assets/img/Catalogo_img/HIDROSOLUBLES/3_10-40-10+AH+EM.webp";
import HI_10_40_10_AH_EM_avif from "./../assets/img/Catalogo_img/HIDROSOLUBLES/3-10-40-10-ah-em.avif";
import HI_20_20_20_png from "./../assets/img/Catalogo_img/HIDROSOLUBLES/4_20-20-20.png";
import HI_20_20_20_webp from "./../assets/img/Catalogo_img/HIDROSOLUBLES/4_20-20-20.webp";
import HI_20_20_20_avif from "./../assets/img/Catalogo_img/HIDROSOLUBLES/4-20-20-20.avif";
import HI_10_10_40_AH_EM_png from "./../assets/img/Catalogo_img/HIDROSOLUBLES/5_10-10-40+AH+EM.png";
import HI_10_10_40_AH_EM_webp from "./../assets/img/Catalogo_img/HIDROSOLUBLES/5_10-10-40+AH+EM.webp";
import HI_10_10_40_AH_EM_avif from "./../assets/img/Catalogo_img/HIDROSOLUBLES/5-10-10-40-ah-em.avif";
import K_HUMES_png from "./../assets/img/Catalogo_img/HIDROSOLUBLES/6_K_HUMES.png";
import K_HUMES_webp from "./../assets/img/Catalogo_img/HIDROSOLUBLES/6_K_HUMES.webp";
import K_HUMES_avif from "./../assets/img/Catalogo_img/HIDROSOLUBLES/6-k-humes.avif";
import POTENZ_BLEND_png from "./../assets/img/Catalogo_img/HIDROSOLUBLES/7_POTENZ_BLEND.png";
import POTENZ_BLEND_webp from "./../assets/img/Catalogo_img/HIDROSOLUBLES/7_POTENZ_BLEND.webp";
import POTENZ_BLEND_avif from "./../assets/img/Catalogo_img/HIDROSOLUBLES/7-potenz-blend.avif";
// [FIN] HIDROSOLUBLES

// [INICIO] CORRECTORES_DE_AGUA_Y_SUELO
import ABLAND_SL_png from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/1_ABLAND_SL.png";
import Litro_Abland_N_png from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/Litro_Abland-N.png";
import ABLAND_SL_wepb from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/1_ABLAND_SL.webp";
import ABLAND_SL_avif from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/1-abland-sl.avif";
import ABLAND_SL_75_png from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/2_ABLAND_SL_75.png";
import ABLAND_SL_75_wepb from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/2_ABLAND_SL_75.webp";
import ABLAND_SL_75_avif from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/2-abland-sl-75.avif";
import ABLAND_P_80_SL_png from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/3_ABLAND_P_80_SL.png";
import ABLAND_P_80_SL_wepb from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/3_ABLAND_P_80_SL.webp";
import ABLAND_P_80_SL_avif from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/3-abland-p-80-sl.avif";
import ABLAND_N_70_SL_png from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/4_ABLAND_N_70_SL.png";
import ABLAND_N_70_SL_wepb from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/4_ABLAND_N_70_SL.webp";
import ABLAND_N_70_SL_avif from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/4-abland-n-70-sl.avif";
import INDITUR_ACID_45_SL_png from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/5_INDITUR_ACID_45_SL.png";
import INDITUR_ACID_45_SL_wepb from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/5_INDITUR_ACID_45_SL.webp";
import INDITUR_ACID_45_SL_avif from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/5-inditur-acid-45-sl.avif";
import HCA_25_png from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/6_HCA_25.png";
import HCA_25_wepb from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/6_HCA_25.webp";
import HCA_25_avif from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/6-hca-25.avif";
import AQUAMILD_40_SL_png from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/7_AQUAMILD_40_SL.png";
import AQUAMILD_40_SL_wepb from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/7_AQUAMILD_40_SL.webp";
import AQUAMILD_40_SL_avif from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/7-aquamild-40-sl.avif";
import DESCOMP_png from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/8_DESCOMP.png";
import DESCOMP_wepb from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/8_DESCOMP.webp";
import DESCOMP_avif from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/8-descomp.avif";
import BOLSA_HUMI_K80_png from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/BOLSA-HUMIK-80.png";
import HUMI_K80_png from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/9_HUMI_K80.png";
import HUMI_K80_wepb from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/9_HUMI_K80.webp";
import HUMI_K80_avif from "./../assets/img/Catalogo_img/CORRECTORES_DE_AGUA_Y_SUELO/9-humi-k80.avif";
// [FIN] CORRECTORES_DE_AGUA_Y_SUELO

export const CORRECTORES_DE_AGUA_Y_SUELO_img = {
  ABLAND_SL_png,
  Litro_Abland_N_png,
  ABLAND_SL_wepb,
  ABLAND_SL_avif,
  ABLAND_SL_75_png,
  ABLAND_SL_75_wepb,
  ABLAND_SL_75_avif,
  ABLAND_P_80_SL_png,
  ABLAND_P_80_SL_wepb,
  ABLAND_P_80_SL_avif,
  ABLAND_N_70_SL_png,
  ABLAND_N_70_SL_wepb,
  ABLAND_N_70_SL_avif,
  INDITUR_ACID_45_SL_png,
  INDITUR_ACID_45_SL_wepb,
  INDITUR_ACID_45_SL_avif,
  HCA_25_png,
  HCA_25_wepb,
  HCA_25_avif,
  AQUAMILD_40_SL_png,
  AQUAMILD_40_SL_wepb,
  AQUAMILD_40_SL_avif,
  DESCOMP_png,
  DESCOMP_wepb,
  DESCOMP_avif,
  BOLSA_HUMI_K80_png,
  HUMI_K80_png,
  HUMI_K80_wepb,
  HUMI_K80_avif,
};

export const HIDROSOLUBLES_img = {
  N_POTENZ_png,
  N_POTENZ_webp,
  N_POTENZ_avif,
  HIDROBOR_png,
  HIDROBOR_webp,
  HIDROBOR_avif,
  HI_10_40_10_AH_EM_png,
  HI_10_40_10_AH_EM_webp,
  HI_10_40_10_AH_EM_avif,
  HI_20_20_20_png,
  HI_20_20_20_webp,
  HI_20_20_20_avif,
  HI_10_10_40_AH_EM_png,
  HI_10_10_40_AH_EM_webp,
  HI_10_10_40_AH_EM_avif,
  K_HUMES_png,
  K_HUMES_webp,
  K_HUMES_avif,
  POTENZ_BLEND_png,
  POTENZ_BLEND_webp,
  POTENZ_BLEND_avif,
};

export const ESPECIALIZADOS_img = {
  MEISTER_PAPA_png,
  MEISTER_PAPA_webp,
  MEISTER_PAPA_avif,
  MEISTER_MAIZ_png,
  MEISTER_MAIZ_webp,
  MEISTER_MAIZ_avif,
  MEISTER_FRIJOL_png,
  MEISTER_FRIJOL_webp,
  MEISTER_FRIJOL_avif,
  MEISTER_CAFE_png,
  MEISTER_CAFE_webp,
  MEISTER_CAFE_avif,
  MEISTER_AJONJOLI_png,
  MEISTER_AJONJOLI_webp,
  MEISTER_AJONJOLI_avif,
  MEISTER_PLUS_png,
  MEISTER_PLUS_webp,
  MEISTER_PLUS_avif,
  ES_8_24_0_png,
  ES_8_24_0_webp,
  ES_8_24_0_avif,
  HUMATO_DE_CALCIO_png,
  HUMATO_DE_CALCIO_webp,
  HUMATO_DE_CALCIO_avif,
  HUMATO_DE_MAGNESIO_png,
  HUMATO_DE_MAGNESIO_webp,
  HUMATO_DE_MAGNESIO_avif,
  POTENZ_K_png,
  POTENZ_K_webp,
  POTENZ_K_avif,
  NITRATO_DE_ZINC_png,
  NITRATO_DE_ZINC_webp,
  NITRATO_DE_ZINC_avif,
  POTENZ_BRIXX_png,
  POTENZ_BRIXX_webp,
  POTENZ_BRIXX_avif,
  MAS_CANA_png,
  MAS_CANA_webp,
  MAS_CANA_avif,
  FOST_ALEXIN_K_png,
  FOST_ALEXIN_K_webp,
  FOST_ALEXIN_K_avif,
  MICROPLUS_png,
  MICROPLUS_webp,
  MICROPLUS_avif,
  QUELATT_Fe_png,
  QUELATT_Fe_webp,
  QUELATT_Fe_avif,
  ES_10_34_0_png,
  ES_10_34_0_webp,
  ES_10_34_0_avif,
};

export const COADYUVANTES_img = {
  PANDER_100_SL_png,
  PANDER_100_SL_webp,
  PANDER_100_SL_avif,
  W_30_SL_png,
  W_30_SL_webp,
  W_30_SL_avif,
  FILM_KOVER_83_EC_png,
  FILM_KOVER_83_EC_webp,
  FILM_KOVER_83_EC_avif,
  PS_MAX_png,
  PS_MAX_webp,
  PS_MAX_avif,
  ADHER_30_SL_png,
  ADHER_30_SL_webp,
  ADHER_30_SL_avif,
  SURFAPLUS_28_png,
  SURFAPLUS_28_webp,
  SURFAPLUS_28_avif,
  POTENZOIL_85_SL_png,
  POTENZOIL_85_SL_webp,
  POTENZOIL_85_SL_avif,
  KAPSER_88_SL_png,
  KAPSER_88_SL_webp,
  KAPSER_88_SL_avif,
  DEW_MAX_90_SL_png,
  DEW_MAX_90_SL_webp,
  DEW_MAX_90_SL_avif,
  EMURALL_85_SL_png,
  EMURALL_85_SL_webp,
  EMURALL_85_SL_avif,
};

export const BIOPROTECTORES_img = {
  FANGE_20_png,
  FANGE_20_webp,
  FANGE_20_avif,
  K_OLEO_png,
  K_OLEO_webp,
  K_OLEO_avif,
  OMICRON_BIO_15_SL_png,
  OMICRON_BIO_15_SL_webp,
  OMICRON_BIO_15_SL_avif,
  OMICRON_BG_2000_png,
  OMICRON_BG_2000_webp,
  OMICRON_BG_2000_avif,
  POTENZ_NEEM_png,
  POTENZ_NEEM_webp,
  POTENZ_NEEM_avif,
  KARAJO_EC_png,
  KARAJO_EC_webp,
  KARAJO_EC_avif,
  POTENZSIL_png,
  POTENZSIL_webp,
  POTENZSIL_avif,
  LANGE_LEBEN_png,
  LANGE_LEBEN_webp,
  LANGE_LEBEN_avif,
  KARANJA_85_SL_png,
  KARANJA_85_SL_webp,
  KARANJA_85_SL_avif,
  K_OLEO_CANELA_png,
  K_OLEO_CANELA_webp,
  K_OLEO_CANELA_avif,
};

export const BIOESTIMULANTES_img = {
  ATP_K_png,
  ATP_K_webp,
  ATP_K_avif,
  MASTER_FLOWER_png,
  MASTER_FLOWER_webp,
  MASTER_FLOWER_avif,
  POTENZAMIN_50_png,
  POTENZAMIN_50_webp,
  POTENZAMIN_50_avif,
  ROOT_DRIVE_png,
  ROOT_DRIVE_webp,
  ROOT_DRIVE_avif,
  ALPROMAR_30_SL_png,
  ALPROMAR_30_SL_webp,
  ALPROMAR_30_SL_avif,
  AMINOACIDOS_80_png,
  AMINOACIDOS_80_webp,
  AMINOACIDOS_80_avif,
  MASTER_15_15_15_png,
  MASTER_15_15_15_webp,
  MASTER_15_15_15_avif,
  MICROAMIN_png,
  MICROAMIN_webp,
  MICROAMIN_avif,
  BAG_ALRPOMAR_100_png,
  ALRPOMAR_100_png,
  ALRPOMAR_100_webp,
  ALRPOMAR_100_avif,
};

export const Agrinutrientes_Img = {
  AGRINUTRIENTE_Zn_b_png,
  AGRINUTRIENTE_Zn_b_webp,
  AGRINUTRIENTE_Zn_b_avif,
  AGRINUTRIENTE_Ca_b_png,
  AGRINUTRIENTE_Ca_b_webp,
  AGRINUTRIENTE_Ca_b_avif,
  AGRINUTRIENTE_17_17_17_png,
  AGRINUTRIENTE_17_17_17_webp,
  AGRINUTRIENTE_17_17_17_avif,
  AGRINUTRIENTE_B_png,
  AGRINUTRIENTE_B_webp,
  AGRINUTRIENTE_B_avif,
};
