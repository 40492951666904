// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

i18n
    .use(initReactI18next) // Conecta react-i18next
    .use(LanguageDetector)
    .use(Backend)
    .init({
        lng: 'es', // Idioma predeterminado
        fallbackLng: 'en', // Idioma de respaldo
        interpolation: {
            escapeValue: false // No escapar HTML en cadenas traducidas
        }

    });

export default i18n;
