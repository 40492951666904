import React, { useEffect, useState } from "react";
import "./Productos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import {
  Agrinutrientes_Img,
  BIOESTIMULANTES_img,
  BIOPROTECTORES_img,
  COADYUVANTES_img,
  CORRECTORES_DE_AGUA_Y_SUELO_img,
  ESPECIALIZADOS_img,
  HIDROSOLUBLES_img,
} from "../../utils/Images";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";

export default function Productos() {
  const { t } = useTranslation();

  // AGRINUTRIENTES
  const products = [
    {
      nombre: "Agrinutriente Zn+B",
      color: "#7abe00",
      png: Agrinutrientes_Img.AGRINUTRIENTE_Zn_b_png,
      webp: Agrinutrientes_Img.AGRINUTRIENTE_Zn_b_webp,
      avif: Agrinutrientes_Img.AGRINUTRIENTE_Zn_b_avif,
      clase_color: "fondo_de_color_1",
    },
    {
      nombre: "Agrinutriente Ca+B",
      color: "#7abe00",
      png: Agrinutrientes_Img.AGRINUTRIENTE_Ca_b_png,
      webp: Agrinutrientes_Img.AGRINUTRIENTE_Ca_b_webp,
      avif: Agrinutrientes_Img.AGRINUTRIENTE_Ca_b_avif,
      clase_color: "fondo_de_color_1",
    },
    {
      nombre: "Agrinutriente 17-17-17",
      color: "#7abe00",
      png: Agrinutrientes_Img.AGRINUTRIENTE_17_17_17_png,
      webp: Agrinutrientes_Img.AGRINUTRIENTE_17_17_17_webp,
      avif: Agrinutrientes_Img.AGRINUTRIENTE_17_17_17_avif,
      clase_color: "fondo_de_color_1",
    },
    {
      nombre: "Agrinutriente B",
      color: "#7abe00",
      png: Agrinutrientes_Img.AGRINUTRIENTE_B_png,
      webp: Agrinutrientes_Img.AGRINUTRIENTE_B_webp,
      avif: Agrinutrientes_Img.AGRINUTRIENTE_B_avif,
      clase_color: "fondo_de_color_1",
    },
    // ESPECIALIDADES
    {
      nombre: "Meister Papa",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_PAPA_png,
      webp: ESPECIALIZADOS_img.MEISTER_PAPA_webp,
      avif: ESPECIALIZADOS_img.MEISTER_PAPA_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Meister Maíz",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_MAIZ_png,
      webp: ESPECIALIZADOS_img.MEISTER_MAIZ_webp,
      avif: ESPECIALIZADOS_img.MEISTER_MAIZ_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Meister Frijol",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_FRIJOL_png,
      webp: ESPECIALIZADOS_img.MEISTER_FRIJOL_webp,
      avif: ESPECIALIZADOS_img.MEISTER_FRIJOL_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Meister Café",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_CAFE_png,
      webp: ESPECIALIZADOS_img.MEISTER_CAFE_webp,
      avif: ESPECIALIZADOS_img.MEISTER_CAFE_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Meister Ajonjolí",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_AJONJOLI_png,
      webp: ESPECIALIZADOS_img.MEISTER_AJONJOLI_webp,
      avif: ESPECIALIZADOS_img.MEISTER_FRIJOL_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Meister Plus",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MEISTER_PLUS_png,
      webp: ESPECIALIZADOS_img.MEISTER_PLUS_webp,
      avif: ESPECIALIZADOS_img.MEISTER_PLUS_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "8-24-0",
      color: "#91016C",
      png: ESPECIALIZADOS_img.ES_8_24_0_png,
      webp: ESPECIALIZADOS_img.ES_8_24_0_webp,
      avif: ESPECIALIZADOS_img.ES_8_24_0_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Humato De Calcio",
      color: "#91016C",
      png: ESPECIALIZADOS_img.HUMATO_DE_CALCIO_png,
      webp: ESPECIALIZADOS_img.HUMATO_DE_MAGNESIO_webp,
      avif: ESPECIALIZADOS_img.HUMATO_DE_CALCIO_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Humato De Magnesio",
      color: "#91016C",
      png: ESPECIALIZADOS_img.HUMATO_DE_MAGNESIO_png,
      webp: ESPECIALIZADOS_img.HUMATO_DE_MAGNESIO_webp,
      avif: ESPECIALIZADOS_img.HUMATO_DE_MAGNESIO_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Potenz-K",
      color: "#91016C",
      png: ESPECIALIZADOS_img.POTENZ_K_png,
      webp: ESPECIALIZADOS_img.POTENZ_K_webp,
      avif: ESPECIALIZADOS_img.POTENZ_K_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Nitrato De Zinc",
      color: "#91016C",
      png: ESPECIALIZADOS_img.NITRATO_DE_ZINC_png,
      webp: ESPECIALIZADOS_img.NITRATO_DE_ZINC_webp,
      avif: ESPECIALIZADOS_img.NITRATO_DE_ZINC_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Potenz Brixx",
      color: "#91016C",
      png: ESPECIALIZADOS_img.POTENZ_BRIXX_png,
      webp: ESPECIALIZADOS_img.POTENZ_BRIXX_webp,
      avif: ESPECIALIZADOS_img.POTENZ_BRIXX_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Más Caña",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MAS_CANA_png,
      webp: ESPECIALIZADOS_img.MAS_CANA_webp,
      avif: ESPECIALIZADOS_img.MAS_CANA_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Fost Alexin-K",
      color: "#91016C",
      png: ESPECIALIZADOS_img.FOST_ALEXIN_K_png,
      webp: ESPECIALIZADOS_img.FOST_ALEXIN_K_webp,
      avif: ESPECIALIZADOS_img.FOST_ALEXIN_K_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Microplus",
      color: "#91016C",
      png: ESPECIALIZADOS_img.MICROPLUS_png,
      webp: ESPECIALIZADOS_img.MICROPLUS_webp,
      avif: ESPECIALIZADOS_img.MICROPLUS_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "Quelatt Fe",
      color: "#91016C",
      png: ESPECIALIZADOS_img.QUELATT_Fe_png,
      webp: ESPECIALIZADOS_img.QUELATT_Fe_webp,
      avif: ESPECIALIZADOS_img.QUELATT_Fe_avif,
      clase_color: "fondo_de_color_2",
    },
    {
      nombre: "10-34-0",
      color: "#91016C",
      png: ESPECIALIZADOS_img.ES_10_34_0_png,
      webp: ESPECIALIZADOS_img.ES_10_34_0_webp,
      avif: ESPECIALIZADOS_img.ES_10_34_0_avif,
      clase_color: "fondo_de_color_2",
    },
    // BIOESTIMULANTES
    {
      nombre: "Atp-K",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.ATP_K_png,
      webp: BIOESTIMULANTES_img.ATP_K_webp,
      avif: BIOESTIMULANTES_img.ATP_K_avif,
      clase_color: "fondo_de_color_3",
    },
    {
      nombre: "Master Flower",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.MASTER_FLOWER_png,
      webp: BIOESTIMULANTES_img.MASTER_FLOWER_webp,
      avif: BIOESTIMULANTES_img.MASTER_FLOWER_avif,
      clase_color: "fondo_de_color_3",
    },
    {
      nombre: "Potenzamin 50",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.POTENZAMIN_50_png,
      webp: BIOESTIMULANTES_img.POTENZAMIN_50_webp,
      avif: BIOESTIMULANTES_img.POTENZAMIN_50_avif,
      clase_color: "fondo_de_color_3",
    },
    {
      nombre: "Root Drive",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.ROOT_DRIVE_png,
      webp: BIOESTIMULANTES_img.ROOT_DRIVE_webp,
      avif: BIOESTIMULANTES_img.ROOT_DRIVE_avif,
      clase_color: "fondo_de_color_3",
    },
    {
      nombre: "Alpromar 30 SL",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.ALPROMAR_30_SL_png,
      webp: BIOESTIMULANTES_img.ALPROMAR_30_SL_webp,
      avif: BIOESTIMULANTES_img.ALPROMAR_30_SL_avif,
      clase_color: "fondo_de_color_3",
    },
    // {
    //   nombre: "Aminoácidos 80%",
    //   color: "#F4C241",
    //   imagen: aminoacidos_80,
    //   clase_color: "fondo_de_color_3",
    // },
    {
      nombre: "15-15-15 Master",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.MASTER_15_15_15_png,
      webp: BIOESTIMULANTES_img.MASTER_15_15_15_webp,
      avif: BIOESTIMULANTES_img.MASTER_15_15_15_avif,
      clase_color: "fondo_de_color_3",
    },
    {
      nombre: "Microamin",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.MICROAMIN_png,
      webp: BIOESTIMULANTES_img.MICROAMIN_webp,
      avif: BIOESTIMULANTES_img.MICROAMIN_avif,
      clase_color: "fondo_de_color_3",
    },
    {
      nombre: "Alpromar 100",
      color: "#F4C241",
      png: BIOESTIMULANTES_img.ALRPOMAR_100_png,
      webp: BIOESTIMULANTES_img.ALRPOMAR_100_webp,
      avif: BIOESTIMULANTES_img.ALRPOMAR_100_avif,
      clase_color: "fondo_de_color_3",
    },
    // BIOPROTECTORES
    {
      nombre: "Fange 20 SL",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.FANGE_20_png,
      webp: BIOPROTECTORES_img.FANGE_20_webp,
      avif: BIOPROTECTORES_img.FANGE_20_avif,
      clase_color: "fondo_de_color_4",
    },
    {
      nombre: "K-Óleo",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.K_OLEO_png,
      webp: BIOPROTECTORES_img.K_OLEO_webp,
      avif: BIOPROTECTORES_img.K_OLEO_avif,
      clase_color: "fondo_de_color_4",
    },
    {
      nombre: "Omicron Bio 15 SL",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.OMICRON_BIO_15_SL_png,
      webp: BIOPROTECTORES_img.OMICRON_BIO_15_SL_webp,
      avif: BIOPROTECTORES_img.OMICRON_BIO_15_SL_avif,
      clase_color: "fondo_de_color_4",
    },
    {
      nombre: "Omicron BF 2000",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.OMICRON_BG_2000_png,
      webp: BIOPROTECTORES_img.OMICRON_BG_2000_webp,
      avif: BIOPROTECTORES_img.OMICRON_BG_2000_avif,
      clase_color: "fondo_de_color_4",
    },
    {
      nombre: "Potenz Neen",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.POTENZ_NEEM_png,
      webp: BIOPROTECTORES_img.POTENZ_NEEM_webp,
      avif: BIOPROTECTORES_img.POTENZ_NEEM_avif,
      clase_color: "fondo_de_color_4",
    },
    {
      nombre: "Karajo Ec",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.KARAJO_EC_png,
      webp: BIOPROTECTORES_img.KARAJO_EC_webp,
      avif: BIOPROTECTORES_img.KARAJO_EC_avif,
      clase_color: "fondo_de_color_4",
    },
    {
      nombre: "Potenzsil",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.POTENZSIL_png,
      webp: BIOPROTECTORES_img.POTENZSIL_webp,
      avif: BIOPROTECTORES_img.POTENZSIL_avif,
      clase_color: "fondo_de_color_4",
    },
    {
      nombre: "Lange Leben",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.LANGE_LEBEN_png,
      webp: BIOPROTECTORES_img.LANGE_LEBEN_webp,
      avif: BIOPROTECTORES_img.LANGE_LEBEN_avif,
      clase_color: "fondo_de_color_4",
    },
    {
      nombre: "Karanja 85 SL",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.KARANJA_85_SL_png,
      webp: BIOPROTECTORES_img.KARANJA_85_SL_webp,
      avif: BIOPROTECTORES_img.KARANJA_85_SL_avif,
      clase_color: "fondo_de_color_4",
    },
    {
      nombre: "K-Óleo + Canela",
      color: "#7F7F7F",
      png: BIOPROTECTORES_img.K_OLEO_CANELA_png,
      webp: BIOPROTECTORES_img.K_OLEO_CANELA_webp,
      avif: BIOPROTECTORES_img.K_OLEO_CANELA_avif,
      clase_color: "fondo_de_color_4",
    },
    //Correctores De Agua Y Suelo
    {
      nombre: "Abland SL",
      color: "#05A0EE",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_avif,
      clase_color: "fondo_de_color_5",
    },
    {
      nombre: "Abland SL 75",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_75_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_75_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_SL_75_avif,
      color: "#05A0EE",
      clase_color: "fondo_de_color_5",
    },
    {
      nombre: "Abland N 70 SL",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_N_70_SL_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_N_70_SL_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_N_70_SL_avif,
      color: "#05A0EE",
      clase_color: "fondo_de_color_5",
    },
    {
      nombre: "Abland P 80 SL",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_P_80_SL_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_P_80_SL_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.ABLAND_P_80_SL_avif,
      color: "#05A0EE",
      clase_color: "fondo_de_color_5",
    },
    {
      nombre: "Abland 85 SL",
      color: "#05A0EE",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.Litro_Abland_N_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.Litro_Abland_N_png,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.Litro_Abland_N_png,
      clase_color: "fondo_de_color_5",
    },
    {
      nombre: "Inditur-Acid 45 SL",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.INDITUR_ACID_45_SL_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.INDITUR_ACID_45_SL_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.INDITUR_ACID_45_SL_avif,
      color: "#05A0EE",
      clase_color: "fondo_de_color_5",
    },
    {
      nombre: "HCA-25",
      color: "#05A0EE",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.HCA_25_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.HCA_25_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.HCA_25_avif,
      clase_color: "fondo_de_color_5",
    },
    {
      nombre: "Aquamild 40 SL",
      color: "#05A0EE",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.AQUAMILD_40_SL_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.AQUAMILD_40_SL_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.AQUAMILD_40_SL_avif,
      clase_color: "fondo_de_color_5",
    },
    {
      nombre: "Descomp",
      color: "#05A0EE",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.DESCOMP_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.DESCOMP_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.DESCOMP_avif,
      clase_color: "fondo_de_color_5",
    },
    {
      nombre: "Humi K80",
      color: "#05A0EE",
      png: CORRECTORES_DE_AGUA_Y_SUELO_img.HUMI_K80_png,
      webp: CORRECTORES_DE_AGUA_Y_SUELO_img.HUMI_K80_wepb,
      avif: CORRECTORES_DE_AGUA_Y_SUELO_img.HUMI_K80_avif,
      clase_color: "fondo_de_color_5",
    },
    // Coadyuvantes
    {
      nombre: "Spander 100 SL",
      color: "#46947D",
      png: COADYUVANTES_img.PANDER_100_SL_png,
      webp: COADYUVANTES_img.PANDER_100_SL_webp,
      avif: COADYUVANTES_img.PANDER_100_SL_avif,
      clase_color: "fondo_de_color_6",
    },
    {
      nombre: "W-30 SL",
      color: "#46947D",
      png: COADYUVANTES_img.W_30_SL_png,
      webp: COADYUVANTES_img.W_30_SL_webp,
      avif: COADYUVANTES_img.W_30_SL_avif,
      clase_color: "fondo_de_color_6",
    },
    {
      nombre: "Film Kover 83 EC",
      color: "#46947D",
      png: COADYUVANTES_img.FILM_KOVER_83_EC_png,
      webp: COADYUVANTES_img.FILM_KOVER_83_EC_webp,
      avif: COADYUVANTES_img.FILM_KOVER_83_EC_avif,
      clase_color: "fondo_de_color_6",
    },
    {
      nombre: "Ps - Max",
      color: "#46947D",
      png: COADYUVANTES_img.PS_MAX_png,
      webp: COADYUVANTES_img.PS_MAX_webp,
      avif: COADYUVANTES_img.PS_MAX_avif,
      clase_color: "fondo_de_color_6",
    },
    {
      nombre: "Adher 30 Sl",
      color: "#46947D",
      png: COADYUVANTES_img.ADHER_30_SL_png,
      webp: COADYUVANTES_img.ADHER_30_SL_webp,
      avif: COADYUVANTES_img.ADHER_30_SL_avif,
      clase_color: "fondo_de_color_6",
    },
    {
      nombre: "Surfaplus 28.7 SL",
      color: "#46947D",
      png: COADYUVANTES_img.SURFAPLUS_28_png,
      webp: COADYUVANTES_img.SURFAPLUS_28_webp,
      avif: COADYUVANTES_img.SURFAPLUS_28_avif,
      clase_color: "fondo_de_color_6",
    },
    {
      nombre: "Potenzoil 85 SL",
      color: "#46947D",
      png: COADYUVANTES_img.POTENZOIL_85_SL_png,
      webp: COADYUVANTES_img.POTENZOIL_85_SL_webp,
      avif: COADYUVANTES_img.POTENZOIL_85_SL_avif,
      clase_color: "fondo_de_color_6",
    },
    {
      nombre: "Kapser 88 SL",
      color: "#46947D",
      png: COADYUVANTES_img.KAPSER_88_SL_png,
      webp: COADYUVANTES_img.KAPSER_88_SL_webp,
      avif: COADYUVANTES_img.KAPSER_88_SL_avif,
      clase_color: "fondo_de_color_6",
    },
    {
      nombre: "Dew - Max 90 SL",
      color: "#46947D",
      png: COADYUVANTES_img.DEW_MAX_90_SL_png,
      webp: COADYUVANTES_img.DEW_MAX_90_SL_webp,
      avif: COADYUVANTES_img.DEW_MAX_90_SL_avif,
      clase_color: "fondo_de_color_6",
    },
    {
      nombre: "Emurall 85 SL",
      color: "#46947D",
      png: COADYUVANTES_img.EMURALL_85_SL_png,
      webp: COADYUVANTES_img.EMURALL_85_SL_webp,
      avif: COADYUVANTES_img.EMURALL_85_SL_avif,
      clase_color: "fondo_de_color_6",
    },
    // HIDROSOLUBLES
    {
      nombre: "N-Potenz",
      color: "#15337D",
      png: HIDROSOLUBLES_img.N_POTENZ_png,
      webp: HIDROSOLUBLES_img.N_POTENZ_webp,
      avif: HIDROSOLUBLES_img.N_POTENZ_avif,
      clase_color: "fondo_de_color_7",
    },
    {
      nombre: "Hidrobor",
      color: "#15337D",
      png: HIDROSOLUBLES_img.HIDROBOR_png,
      webp: HIDROSOLUBLES_img.HIDROBOR_webp,
      avif: HIDROSOLUBLES_img.HIDROBOR_avif,
      clase_color: "fondo_de_color_7",
    },
    {
      nombre: "10-40-10+AH+EM",
      color: "#15337D",
      png: HIDROSOLUBLES_img.HI_10_40_10_AH_EM_png,
      webp: HIDROSOLUBLES_img.HI_10_40_10_AH_EM_webp,
      avif: HIDROSOLUBLES_img.HI_10_40_10_AH_EM_avif,
      clase_color: "fondo_de_color_7",
    },
    {
      nombre: "20-20-20",
      color: "#15337D",
      png: HIDROSOLUBLES_img.HI_20_20_20_png,
      webp: HIDROSOLUBLES_img.HI_20_20_20_webp,
      avif: HIDROSOLUBLES_img.HI_20_20_20_avif,
      clase_color: "fondo_de_color_7",
    },
    {
      nombre: "10-10-40+AH+EM",
      color: "#15337D",
      png: HIDROSOLUBLES_img.HI_10_10_40_AH_EM_png,
      webp: HIDROSOLUBLES_img.HI_10_10_40_AH_EM_webp,
      avif: HIDROSOLUBLES_img.HI_10_10_40_AH_EM_avif,
      clase_color: "fondo_de_color_7",
    },
    {
      nombre: "K Humus",
      color: "#15337D",
      png: HIDROSOLUBLES_img.K_HUMES_png,
      webp: HIDROSOLUBLES_img.K_HUMES_webp,
      avif: HIDROSOLUBLES_img.K_HUMES_avif,
      clase_color: "fondo_de_color_7",
    },
    {
      nombre: "Potenz Blend",
      color: "#15337D",
      png: HIDROSOLUBLES_img.POTENZ_BLEND_png,
      webp: HIDROSOLUBLES_img.POTENZ_BLEND_webp,
      avif: HIDROSOLUBLES_img.POTENZ_BLEND_avif,
      clase_color: "fondo_de_color_7",
    },
  ];

  const ordenAleatorio = () => {
    const productosOrdenados = [...products].sort(() => Math.random() - 0.5);
    return productosOrdenados.slice(0, 6);
  };

  const [productosOrdenados, setProductosOrdenados] = useState([]);

  useEffect(() => {
    // Llama a la función de orden aleatorio al cargar la página
    setProductosOrdenados(ordenAleatorio());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Número de elementos a mostrar en PC
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Cuando el ancho de la pantalla sea menor o igual a 768px (puedes ajustar este valor)
        settings: {
          slidesToShow: 1, // Número de elementos a mostrar en dispositivos móviles
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Array de colores para los divs del carrusel
  const handleProductClick = (productId) => {
    // Implementa la lógica de lo que deseas que haga el botón aquí
    // alert(`Haz hecho clic en el producto ${productId}`);
  };

  return (
    <div id="Produtos" className="product-carousel-container">
      <div style={{ textAlign: "center" }}>
        <span className="titulo_producto bold">{t("text_7")}</span>
      </div>

      <Slider {...settings}>
        {productosOrdenados.map((product) => (
          <div key={product.nombre} className="product-slide">
            <Fade triggerOnce>
              <Link
                to={`/producto/${product.nombre}`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className={`product-contenedor ${product.clase_color}`}>
                  <div
                    className="contenedor_img"
                    // style={{
                    //   backgroundColor: `${product.color}`,
                    // }}
                  >
                    {/* <img
                      src={product.png}
                      alt={product.nombre}
                      className="product-image"
                    /> */}
                    {/* <div className="product-image"></div> */}

                    <picture>
                      <source
                        className="img-fluid product-image"
                        srcSet={product.webp}
                        type="image/webp"
                      />
                      <source
                        className="img-fluid product-image"
                        srcSet={product.avif}
                        type="image/avif"
                      />

                      <img
                        className="img-fluid product-image"
                        src={product.png}
                        alt={product.nombre}
                      />
                    </picture>
                  </div>
                  <h3 className="regular">{product.nombre}</h3>
                  <button
                    className="product-button regular"
                    onClick={() => handleProductClick(product.id)}
                  >
                    {t("See_details")}
                  </button>
                </div>
              </Link>
            </Fade>
          </div>
        ))}
      </Slider>
    </div>
  );
}
