import React, { createContext, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

const LanguageContext = createContext();

export function useLanguage() {
    return useContext(LanguageContext);
}

export function LanguageProvider({ children }) {
    const { t, i18n } = useTranslation();

    const [language, setLanguage] = useState("es"); // Establece el idioma predeterminado

    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage)


    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
}
